import { MenuIcon, NWSTooltip } from "@nws/nws-ui-library";
import React, { useRef, useState,useContext } from "react";
import { addNewtabwindow, permissionWrapper } from "../../Helpers/helper";
import { useOutsideAlerter } from "../Shared/useOutsideAlerter";
import { useTranslate } from "../../hooks/translation/useTranslationHook";
import {GlobalStateContext,GlobalStateKeys} from "../../Context/GlobalStateContext";
import { defaultListObj } from "../../Helpers/constants";

export const MenuItem = (props) => {
  const { name, iconName, level, tabParams, subMenu, isLastChild, menuSelected, setMenuSelected,className } = props;
  const wrapperRef = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const [clickedMenuItem, setClickedMenuItem] = useState([{ menuId: 0, isCollapse: false }])
  const { t } = useTranslate()

  const handleClickedMenu = (menuId) => {
    let selectedItem = clickedMenuItem.find(item => item.menuId === menuId)
    var clickedMenu = selectedItem ? { menuId: selectedItem.menuId, isCollapse: !selectedItem.isCollapse } : { menuId: menuId, isCollapse: true }
    setClickedMenuItem([clickedMenu, ...clickedMenuItem])
  }

  useOutsideAlerter(wrapperRef, () => {
    setOpen(false);
  });

  const onMenuClick = (tabName, pageLink, isParent, menuId) => {
    if (pageLink != undefined) {
      addNewtabwindow(
        tabName,
        pageLink,
        [...tabParams.tabData, { menuId }],
        tabParams.setTabData,
        tabParams.setActiveKey,
        tabParams.setToggle,
        tabParams.collapsed,
      );
    }
    if (!isParent) {
      setOpen(false);
    } else {
      handleClickedMenu(menuId);
    }
    setMenuSelected({ name: tabName, menuId: menuId });
  };

  const hasAccess = (menuId, access) => {
    let hasPermission = true
    if (permissionWrapper.permissionData) {
      permissionWrapper.permissionData.forEach((element) => {
        if (element.menuID === menuId) {
          hasPermission = access ? permissionWrapper.permissionData.find((element) => element.menuID === menuId)[access] : permissionWrapper.permissionData.find((element) => element.menuID === menuId)
            .accessView
        } else {
          if (element.children) {
            element.children.find((item) => {
              if (item.menuID === menuId) {
                hasPermission = access ? item[access] : item.accessView
              } else {
                if (item.children) {
                  item.children.find((ele) => {
                    if (ele.menuID === menuId) {
                      hasPermission = access ? ele[access] : ele.accessView
                    }
                  })
                }
              }
            })
          }
        }
      })

    }

    return hasPermission;
  }

  const buildSubMenu = (subMenu) => {
    return (
      subMenu &&
      subMenu.map((item, index) => {
        return (
          item.isSideBarMenu && hasAccess(item.menuId, item.access) && (
            <ChildMenu
              item={item}
              onMenuClick={onMenuClick}
              menuSelected={menuSelected}
              clickedMenuItem={clickedMenuItem}
              key={`sub-menu-${index}`}
              tabParams={tabParams}
              className={className}
            >
              {item.children && <ul>{buildSubMenu(item.children)}</ul>}
            </ChildMenu>
          )
        );
      })
    );
  };

  const selectedMenu = subMenu && subMenu.find((elem) => {
    let hasAccess = false
    if (elem.menuId === menuSelected.menuId) {
      hasAccess = true
    } else {
      elem.children && elem.children.find((item) => {
        if (item.menuId === menuSelected.menuId) {
          hasAccess = true
        }
      })
    }
    return hasAccess
  })

  return (
    <li
      className={`nw-main-nav--item nav-item--level-${level} ${isLastChild ? "nw-menu-last-child" : ""
        } ${selectedMenu ? "main-menu-active" : ""}`}
    >
      {isLastChild && <hr className="nw-menu-hr"></hr>}
      <a
        className="nw-main-nav--item--link"
        href=""
        onClick={(e) => {
          e.preventDefault();
          setOpen(true);
        }}
        ref={wrapperRef}
      >
        <div className="nw-sidebar-menu-button">
          <ul className={`nw-sidebar-menu-button__item ${isOpen ? " active" : ""}`}>
            <div className={className}>
            <NWSTooltip title={t(name)}>
              <div>
                <MenuIcon
                  iconName={iconName}
                  className="svg-menu-icon"
                  height="2em"
                  width="2em"
                />
              </div>
            </NWSTooltip>
            </div>
            <div
              className={`nw-active-menu-popover ${isOpen ? " active" : ""}`}
            >
              <span className="nw-menu-group-heading">{t(name)}</span>
              <hr className="nw-menu-hr--group-heading" />
              <ul className="nw-main-nav nw-global-nav">
                {subMenu && buildSubMenu(subMenu)}
              </ul>
            </div>
          </ul>
        </div>
      </a>
      {!isLastChild && <hr className="nw-menu-hr"></hr>}
    </li>
  );
};

const ChildMenu = ({
  item,
  onMenuClick,
  menuSelected,
  children,
  clickedMenuItem,
  tabParams
}) => {

  const selectedMenuItem = clickedMenuItem.find((menu) => menu.menuId === item.menuId)
  const { t } = useTranslate()
  const { setGlobalStateMany} = useContext(GlobalStateContext);

  const initialDocumentValue = defaultListObj(
    true,
    false,
    false,
    "id",
    "type",
    "0",
    "--All--"
  );
  const dynamicMenuItems = {
    taskList: t("menuItem.taskList"),
    documentManagement: t("documentManagement.documentManagement"),
  };
  
  const onMenuItemClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onMenuClick(t(item.name), item.linkUrl, children ? true : false, item.menuId);

    if (
      t(item.name) == dynamicMenuItems.taskList ||
      t(item.name) == dynamicMenuItems.documentManagement
    ) {
      setGlobalStateMany(
        [GlobalStateKeys.TaskListDetails, GlobalStateKeys.DocumentListDetails],
        [
          {
            clientId: undefined,
            fromClientDashboard: false,
            shouldRefetch: true,
          },
          {
            type: initialDocumentValue,
            name: "",
            description: "",
            searchText: "",
            documents: [],
            client: defaultListObj(false, true, false),
            fiscalYear: defaultListObj(false, true, true),
            fromClientDashboard: false,
          },
        ]
      );
    }
  };

  const tabsDetails = tabParams && tabParams.tabData.some((element) => element.title === menuSelected.name)

  return (
    <li
      data-value={t(item.name)}
      className={`nw-sidebar-list-item-perent ${selectedMenuItem && selectedMenuItem.isCollapse ? "collapsed" : "expanded"}`}
    >
      <div
        className={`nw-sidebar-list-item ${menuSelected.menuId === item.menuId && tabsDetails
          ? " active-menu"
          : "nw-sidebar-list-item-hover-style"
          } `}
        onClick={onMenuItemClick}
      >
        <MenuIcon
          iconName={item.iconName}
          className={`svg-menu-element-icon-big${selectedMenuItem && selectedMenuItem.isCollapse || children ? " rot-90" : ""}`}
        />
        <span className="nw-link-label">{t(item.name)}</span>
      </div>
      {children}
    </li>
  );
};
