import React from "react";
import { LoadingSkeleton } from "./LoadingSkeleton ";
import { MenuIcon } from "@nws/nws-ui-library";
import { idleStatus } from "./Constants";

export const DashboardCardContent = ({
  iconName,
  displayLabel,
  count,
  isLoading,
  status,
}) => (
  <>
    {status == idleStatus || isLoading ? (
      <LoadingSkeleton />
    ) : (
      <>
        <MenuIcon iconName={iconName} width="3.5em" height="3.5em" />
        <label>{displayLabel}</label>
        <strong>{count}</strong>
      </>
    )}
  </>
);
