import React from 'react'
import { IconPalette } from '@nws/nws-ui-library'
import './scss/global.scss'
import { UserDataContextProvider } from './Context/UserDataContext'
import LoginPage from './pages/Login/LoginPage'
import { HashRouter, Route } from 'react-router-dom'
import LandingPage from './pages/LandingHome/landingPage'
import { ToastContainer } from 'react-toastify';
import './styles/toastifyStyle.css'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Toast_LIMIT } from './components/Shared/Constants/ToastLimit.constants'
// import { fetchToken, onMessageListener } from './Helpers/notification/firebase'
// import { nwstoast, toastType } from "./Helpers/NWSToast"
import { GlobalStateContextProvider } from './Context/GlobalStateContext'
const queryClient = new QueryClient()

const App = () => {
  // fetchToken();

  // onMessageListener().then(payload => {
  //   nwstoast(payload.notification.title, toastType.Info, 5000)
  // }).catch(err => console.error('Notification failed: ', err));

  return <GlobalStateContextProvider>
    <UserDataContextProvider>
      <QueryClientProvider client={queryClient}>
        <HashRouter>
          <Route path="/" component={LoginPage} exact />
          <Route path="/Login" component={LoginPage} />
          <Route path="/LandingHome" component={LandingPage} />
        </HashRouter>
        <IconPalette />
        <ToastContainer limit={Toast_LIMIT} />
      </QueryClientProvider>
    </UserDataContextProvider>
  </GlobalStateContextProvider>
}

export default App
