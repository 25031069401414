export const typeOfEntityList = (isSelect = false) => [
  { id: "0", value: isSelect ? "--Select--" : "--All--" },
  { id: "Ltd", value: "Ltd" },
  { id: "Pvt Ltd", value: "Pvt Ltd" },
  { id: "LLP", value: "LLP" },
  { id: "Proprietary", value: "Proprietary" },
  { id: "Partnership Firm", value: "Partnership Firm" },
  { id: "Individual", value: "Individual" },
  { id: "Church", value: "Church" },
  { id: "Trust", value: "Trust" },
  { id: "Society", value: "Society" },
  { id: "Others", value: "Others" },
];
export const natureOfBusinessList = (isSelect = false) => [
  { id: "0", value: isSelect ? "--Select--" : "--All--" },
  { id: "Trading", value: "Trading" },
  { id: "Manufacturing", value: "Manufacturing" },
  { id: "Services", value: "Services" },
];
export const typeOfClientList = (isSelect = false) => [
  { id: "0", value: isSelect ? "--Select--" : "--All--" },
  { id: "Continues", value: "Continues" },
  { id: "First Time", value: "First Time" },
];
export const statusList = (isSelect = false) => [
  { id: "0", value: isSelect ? "--Select--" : "--All--" },
  { id: "Audit Client", value: "Audit Client" },
  { id: "Audit & Analysis", value: "Audit & Analysis" },
  { id: "Internal Audit & Analysis", value: "Internal Audit & Analysis" },
];
export const FileType = [
  { id: "0", value: "--Select--" },
  { id: "Trade Receivable Ledger", value: "Trade Receivable Ledger" },
  { id: "Trade Payable Ledger", value: "Trade Payable Ledger" },
  { id: " Stock", value: " Stock" },
  { id: "Other Ledgers", value: "Other Ledgers" },
];
export const categoryList = [
  { id: "0", value: "--Select--" },
  { id: "A", value: "A" },
  { id: "B", value: "B" },
  { id: "C", value: "C" },
  { id: "D", value: "D" },
];
export const auditStatusTypes = [
  { id: "Start", value: "Start" },
  { id: "Update", value: "Update" },
  { id: "Complete", value: "Complete" },
];
export const auditStatusTypesNotStarted = [{ id: "Start", value: "Start" }];
export const auditStatusTypesStarted = [{ id: "Update", value: "Update" }];
export const auditStatusTypesCompleted = [{ id: "Approve", value: "Approve" }];

export const engagmtList = [
  { id: "Internal Audit", value: "Internal Audit" },
  { id: "Statutory Audit", value: "Statutory Audit" },
  { id: "IncomeTax Audit", value: "IncomeTax Audit" },
  { id: "GST Audit", value: "GST Audit" },
  { id: "Stock Audit", value: "Stock Audit" },
  { id: "Bank Audit", value: "Bank Audit" },
  { id: "Special Audit", value: "Special Audit" },
  { id: "Compilation", value: "Compilation" },
  { id: "Agreed upon procedure", value: "Agreed upon procedure" },
];

export const menuCardTypeConstats = {
  TrialBalance: "TrialBalance",
  ProfitAndLoss: "ProfitAndLoss",
  BalanceSheet: "BalanceSheet",
  CashFlow: "CashFlow",
  Ratio: "Ratio",
  FaIt: "FixedAssetIncomeTax",
  Fa: "FixedAsset",
  NotesPl: "NotesProfitAndLoss",
  NoteBs: "NotesBalanceSheet",
  RatioAnalysis: "RatioAnalysis",
  Schedules: "Schedules",
  AuditReport: "AuditReport",
  DirectorReport: "DirectorReport",
  CaroReport: "CaroReport",
  IndexReport: "IndexReport",
  FixedAssetRegister: "fixedAssetRegister",
};

export const reportViewType = {
  popup: "popup",
  popover: "popover",
  slidemenu: "slideMenu",
  tabType: "tabType",
};

export const ClientDetailTypes = {
  ActiveClients: "Active Clients",
  NewEngegements: "Active Engagements",
  EngagementContinuous: "Active EngagementContinues",
};

export const dashBoardTypes = {
  Engagements: "Engagements",
  PlanningCompleted: "Planning Completed",
  CurrentYearPlanning: "Current Year Planning",
  ProcedureInProgress: "Procedure In Progress",
  ProcedureCompleted: "Procedure Completed",
  ProcedureReviewPending: "Review Pending",
  ProcedureApprovePending: "Approve Pending",
};

export const pAndLGroups = {
  Incomes: "Incomes",
  LessCostOfSales: "Less Cost Of Sales",
  OperatingExpenses: "Operating Expenses",
  OtherIncome: "OtherIncome",
};

export const typeOfEntity = {
  privarteLtd: "Pvt Ltd",
};
