export const MenuCode = {
  home: "home",
  client: "client",
  clientDashboard: "clientDashboard",
  engagement: "engagement",
  engagementContinous: "engagementContinous",
  planning: "planning",
  procedure: "procedure",
  substantiveProcedure: "substantiveProcedure",
  ifrsChecklist: "ifrsChecklist",
  partnerChecklist: "partnerChecklist",
  finalReviewChecklist: "finalReviewChecklist",
  auditOptionChecklist: "auditOptionChecklist",
  representationLetter: "representationLetter",
  specificRepresentationLetter: "specificRepresentationLetter",
  profitAndLoss: "profitAndLoss",
  balanceSheet: "balanceSheet",
  invoice: "invoice",
  company: "company",
  schedule: "schedule",
  profitAndLossSetting: "profitAndLossSetting",
  balanceSheetSetting: "balanceSheetSetting",
  role: "role",
  user: "user",
  taskManagement: "taskManagement",
  fixedAssetITSettings: "fixedAssestITSettings",
  documentManagement: "documentManagement",
  accountGrouping: "accountGrouping",
  engagementLetter: "engagementLetter",
  fixedAssetCASettings: "fixedAssetCASettings",
  contacts: "contacts",
  employeeSettings: "Employee Settings",
  lead: "lead",
  taskDashboard: "taskDashboard",
  taskList: "taskList",
  projectList: "projectList",
  profitAndLossAndBalanceSheet: "profitAndLossAndBalanceSheet",
  notes: "notes",
  emailBody: "emailBody",
  settings: "Settings",
  clientSettings: "Client Settings",
  teams: "Teams",
  notesReport: "notesReport",
  costAnalysisReport: "costAnalysisReport",
  tasbBoard: "tasbBoard",
  clientCredentialMgr: "clientCredentialMgr",
  taskReports: "taskReports",
  gradeMapping: "Grade Mapping",
  taskSchedule: "Task Schedule",
  auditReport: "auditReport",
  auditReportSettings: "auditReportSettings",
  caroReportSettings: "caroReportSettings",
  caroReport: "caroReport",
  directorsReportSettings: "directorsReportSettings",
  directorReport: "directorReport",
  ratioAnalysisSettings: "ratioAnalysisSettings",
  cashFlowReportSettings: "cashFlowReportSettings",
  timeSheet: "timeSheet",
  taskGeneralSettings: "General Settings",
  materialityRange: "materialityRange",
  balanceSheetReport: "balanceSheetReport",
  checklistSettings: "checklistSettings",
  timesheetReport: "timesheetReport",
};
