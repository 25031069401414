import axios from "axios";
import { nwstoast, toastType } from "../../Helpers/NWSToast";
import Events from "../../Helpers/pubsub/events";
import { pubSub } from "../../Helpers/pubsub/pubsub";
import { apiURLs } from "../Constants/Http.constants";
import { setInterceptor } from "./AxiosInterceptor";

class HttpHelper {
  // The axios instnace, new instance in the wrappe so that the default one using other areas will not have any impact.
  axiosInstance;
  constructor(apiUrl) {
    this.axiosInstance = axios.create();
    // TODO: move this to env variable
    this.axiosInstance.defaults.baseURL = apiUrl || apiURLs.BaseUrl;
    setInterceptor(this.axiosInstance);
  }

  /**
   * The function to initiate the http get method
   * @param {String} url  - the endpoint (relational url of API)
   * @param {Boolean} bypassGlobalError - Whether to bypass the global error handling or not
   * @param {Object} config - Additional configurations if any
   */
  async get(url, showLoading = false, bypassGlobalError = false, config) {
    let that = this;
    showLoading && pubSub.publish(Events.LOADING_INITIATED);
    const result = await this.axiosInstance.get(url, config).catch((err) => {
      that.handleError(err, bypassGlobalError);
    });
    showLoading && pubSub.publish(Events.LOADING_COMPLETED);
    return result;
  }

  /**
   * The function to initiate the http post method
   * @param {String} url  - the endpoint (relational url of API)
   * @param {Object} payload - the data to create
   * @param {Boolean} showLoading - Whether to show global spinner or not while api call in process.
   * @param {Boolean} bypassGlobalError - Whether to bypass the global error handling or not
   * @param {Object} config - Additional configurations if any
   * @param {Boolean} isResponsePreviewHide - need show response preview on toast or not
   */
  async post(
    url,
    payload,
    showLoading = false,
    bypassGlobalError = false,
    config,
    isResponsePreviewHide = false
  ) {
    let that = this;
    showLoading && pubSub.publish(Events.LOADING_INITIATED);
    const result = await this.axiosInstance
      .post(url, payload, config)
      .catch((err) => {
        that.handleError(err, bypassGlobalError, isResponsePreviewHide);
      });
    showLoading && pubSub.publish(Events.LOADING_COMPLETED);
    return result;
  }

  /**
   *
   * @param {String} url  - the endpoint (relational url of API)
   * @param {Object} payload - the data to update
   * @param {Boolean} showLoading - Whether to show global spinner or not while api call in process.
   * @param {Boolean} bypassGlobalError - Whether to bypass the global error handling or not
   * @param {Object} config - Additional configurations if any
   */
  async put(
    url,
    payload,
    showLoading = false,
    bypassGlobalError = false,
    config
  ) {
    let that = this;
    showLoading && pubSub.publish(Events.LOADING_INITIATED);
    const result = await this.axiosInstance
      .put(url, payload, config)
      .catch((err) => {
        that.handleError(err, bypassGlobalError);
      });
    showLoading && pubSub.publish(Events.LOADING_COMPLETED);
    return result;
  }

  /**
   *
   * @param {String} url  - the endpoint (relational url of API)
   * @param {Object} payload - the data to update
   * @param {Boolean} showLoading - Whether to show global spinner or not while api call in process.
   * @param {Boolean} bypassGlobalError - Whether to bypass the global error handling or not
   * @param {Object} config - Additional configurations if any
   */
  async patch(
    url,
    payload,
    showLoading = false,
    bypassGlobalError = false,
    config
  ) {
    let that = this;
    showLoading && pubSub.publish(Events.LOADING_INITIATED);
    const result = await this.axiosInstance
      .patch(url, payload, config)
      .catch((err) => {
        that.handleError(err, bypassGlobalError);
      });
    showLoading && pubSub.publish(Events.LOADING_COMPLETED);
    return result;
  }

  /**
   *
   * @param {String} url  - the endpoint (relational url of API)
   * @param {Boolean} showLoading - Whether to show global spinner or not while api call in process.
   * @param {Boolean} bypassGlobalError - Whether to bypass the global error handling or not
   * @param {Object} config - Additional configurations if any
   */
  async delete(url,payload, showLoading = false, bypassGlobalError = false, config) {
    let that = this;
    showLoading && pubSub.publish(Events.LOADING_INITIATED);
    const result = await this.axiosInstance.delete(url,
      { ...config, headers: { 'Content-Type': 'application/json; charset=utf-8' }, data: payload  }).catch((err) => {
        that.handleError(err, bypassGlobalError);
      });
    showLoading && pubSub.publish(Events.LOADING_COMPLETED);
    return result;
  }

  handleError(err, bypassGlobalError, isResponsePreviewHide) {
    pubSub.publish(Events.LOADING_COMPLETED);
    // TODO: log the error.
    if (bypassGlobalError) {
      throw err;
    } else if (err.response.status === 401 || err.response.status === 403) {
      throw err;
    } else if (isResponsePreviewHide) {
      throw err;
    } else {
      nwstoast(err.response.data, toastType.Error, 10000);
    }
  }
}

const httpHelper = new HttpHelper();
export default httpHelper;
export const messageHttpHelper = new HttpHelper(apiURLs.MessageApi);
export const loginHttpHelper = new HttpHelper(apiURLs.LoginUrl);
export const taskManagementHttpHelper = new HttpHelper(apiURLs.TaskManagementApi)
