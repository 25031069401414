import React from 'react'
import { useStore } from '../../../store';

export default function CompanyName() {    
  const [setZCompanyName, z_CompanyName] = useStore((state) => [state.setZCompanyName, state.z_CompanyName]);
    return (
        <span className="nw-header-account--name">
     {z_CompanyName}
    </span>
    )
}
