import React, { createContext, useState } from "react";
import { DEFAULT_DATE_FORMAT } from "../Helpers/constants";

export const UserDataContext = createContext();

/**
 * Context to keep the user specific data like settings, formats etc.
 * TODO: Add the other configurations and values here
 */
export const UserDataContextProvider = (props) => {
  const [userData, setUserData] = useState({ dateFormat: DEFAULT_DATE_FORMAT });

  return (
    <UserDataContext.Provider
      value={{
        userData,
        setUserData
      }}
    >
      {props.children}
    </UserDataContext.Provider>
  );
};
