import React, { useEffect, useState } from "react";
import { NWSPopup, NWSTextField } from "@nws/nws-ui-library";
import { UserProfilePopup } from "../../../Services/UserProfileUpdate.Services";
import { FooterButton } from "../../Shared/FooterButton";
import { nwstoast, toastType } from "../../../Helpers/NWSToast";
import { ImageUpload } from "../ImageUpload";
import { useStore } from "../../../store";
import { getUserByID } from "../../../Services/Settings.Services";
import { getUserId } from "../../../Helpers/user.helper";
import { useTranslate } from "../../../hooks/translation/useTranslationHook";
import { getBase64 } from "../../../Helpers/helper";

export default function UserProfileUpdate({
  userProfileopenModal,
  setUserProfileOpenModal,
}) {
  const { t } = useTranslate();
  const [usrPflUsername, setusrPflUsername] = useState("");
  const [usrPflEmail, setusrPflEmail] = useState("");
  const [usrPflPhone, setusrPflPhone] = useState("");
  const [usrPrlfImgB64, setusrPrlfImgB64] = useState("");
  const [file, setFile] = useState(null);
  const [userDetails, setUserDetails] = useState()
  const [SetUserData, userData] = useStore((state) => [
    state.SetUserData,
    state.userData,
  ]);
  const handleUserProfileModalClose = () => {
    setUserProfileOpenModal(false);
  };
  const updateUserProfile = () => {
    if (usrPflEmail.trim().length <= 0) {
      nwstoast(
        t("commonToast.enterEmailToast"),
        toastType.Error
      );
      return false;
    } else if (usrPflPhone.trim().length <= 0) {
      nwstoast(
        t("userDetails.userProfilePopup.enterPhoneToast"),
        toastType.Error
      );
      return false;
    } else {
      UserProfilePopup({
        id: userDetails.id,
        email: usrPflEmail,
        mobilePhone: usrPflPhone,
        photoImage: usrPrlfImgB64,
        userName: userDetails.userName,
        isBlocked: userDetails.isBlocked,
        roleID: userDetails.roleID,
        role: userDetails.role,
        companyID: userDetails.companyID,
        companyName: userDetails.companyName,
        countryID: userDetails.countryID,
        country: userDetails.country,
        modifiedID: userData.id,
        modifiedUser: userData.userName,
        modifiedDate: new Date()
      }).then(function (response) {
        if (response) {
          nwstoast(
            t("userDetails.userProfilePopup.profileUpdateSuccess"),
            toastType.Success
          );
        } else {
          nwstoast(
            t("userDetails.userProfilePopup.profileUpdateError"),
            toastType.Error
          );
        }
      });
    }
  };

  const usrProfileImageChange = (event) => {
    setFile(URL.createObjectURL(event.target.files[0]));
    const filePath = event.target.files[0];
    getBase64(filePath).then((data) => setusrPrlfImgB64(data));
  };

  useEffect(() => {
    const userId = getUserId()
    getUserId() &&
      getUserByID(userId).then(({ response }) => {
        if (response.data) {
          const responseData = response.data;
          setUserDetails(responseData)
          setusrPflUsername(responseData.userName);
          setusrPflEmail(responseData.email);
          setusrPflPhone(responseData.mobilePhone);
          setusrPrlfImgB64(responseData.photoImage);
        }
      });
  }, []);

  return (
    <NWSPopup
      onClose={handleUserProfileModalClose}
      className=""
      open={userProfileopenModal}
      header={
        <span>{t("commonLabels.userProfile")}</span>
      }
      title={t("commonLabels.userProfile")}
      width="md"
      body={
        <div className="nw-user-prof-main grid-column-1-1">
          <div className="nw-userpopup d-grid ">
            <ImageUpload
              handleChange={usrProfileImageChange}
              file={usrPrlfImgB64}
              menuHeight="2em"
              menuWidth="2em"
            />
          </div>

          <div className="d-grid">
            <NWSTextField
              readOnly={true}
              id="Username"
              label={t("commonLabels.username")}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{ readOnly: true }}
              onChange={(e) => {
                setusrPflUsername(e.target.value);
              }}
              value={usrPflUsername}
            />

            <NWSTextField
              isRequired={true}
              id="Email"
              label={t("commonLabels.email")}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                setusrPflEmail(e.target.value);
              }}
              value={usrPflEmail}
            />
            <NWSTextField
              id="Mobile No"
              label={t("commonLabels.mobile")}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                setusrPflPhone(e.target.value);
              }}
              value={usrPflPhone}
              isRequired={true}
            />
          </div>
        </div>
      }
      footer={
        <div>
          <FooterButton onSave={updateUserProfile} type="Save" />
        </div>
      }
    />
  );
}
