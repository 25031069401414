import React, { useState } from "react";
import { Button, NWSTextField } from "@nws/nws-ui-library";
import { useStore } from "../../store";
import Picture from "../Login/picture";
import { authenticate, getRole } from "../../Services/Login.Service";
import LargeLogoContainer from "../../components/Shared/Header/LargeLogoContainer";
import { Footer } from "../../components/Shared/Footer";
import { useHistory } from "react-router-dom";
import { setUserLoginData } from "../../Helpers/user.helper";
import { LaodingSpinner } from "../../components/Shared/LoadingSpinner";
import { useTranslate } from "../../hooks/translation/useTranslationHook";
import { CLIENT_ROLL_ID } from "../../components/Shared/Constants/Polling.constant";

export default function LoginPage(props) {
  const { t } = useTranslate();
  const router = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState("");
  const [setIsLoggedIn, isLoggedIn] = useStore((state) => [
    state.setIsLoggedIn,
    state.isLoggedIn,
  ]);
  const validate = () => {
    let valid = true;
    if (email.length <= 0) {
      setLoginError(t("loginPage.enterUserNameError"));
      valid = false;
    } else if (password.length <= 0) {
      setLoginError(t("commonToast.passwordError"));
      valid = false;
    } else {
      setLoginError("");
    }
    return valid;
  };

  const handleForgot = async (e) => {
    e.preventDefault();
    setLoginError(t("loginPage.forgetPasswordError"));
  };

  const handleLogin = async (e) => {
    setLoginError("");
    if (validate()) {
      try {
        const response = await authenticate(email, password);
        if (response.data && response.data.jwtToken) {
          setUserLoginData(response.data);
          getRole(response.data.roleID, false).then(({ result }) => {
            if (response.data.roleID != CLIENT_ROLL_ID && result.data.length > 0) {
              setIsLoggedIn(true);
              router.push("LandingHome");
            } else {
              setLoginError(t('loginPage.loginFailRole'));
            }
          });
        }
      } catch (error) {
        if (error.response && error.response.data) {
          setLoginError(error.response.data);
        } else {
          setLoginError(t("loginPage.usernameOrPasswordError"));
        }
      }
    }
  };

  const enterSubmit = (event) => {
    if (event.key === "Enter") {
      handleLogin(event);
    }
  };

  return (
    <section className="nw-login-section">
      <LaodingSpinner />
      <div className="nw-login-wrap">
        <div className="large-3 nw-login-form-col">
          <section className="nw-login--container">
            <LargeLogoContainer />
            <LoginHeader />
            <NWSTextField
              id="UserName"
              className="nw-login-username"
              label={t("loginPage.usernameLabel")}
              placeHolder={t("loginPage.usernamePlaceholder")}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setLoginError("");
              }}
            />
            <NWSTextField
              id="password"
              name="password"
              label={t("loginPage.passwordLabel")}
              type="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setLoginError("");
              }}
              onKeyDown={enterSubmit}
              helperText={loginError}
            />
            <Button
              label={t("loginPage.loginButtonLabel")}
              className="no-margin-bottom"
              onClick={handleLogin}
            />
            <div className="nw-login--forget-wrap">
              <a className="nw-frgt-pw" href="" onClick={handleForgot}>
                {t("loginPage.forgetPasswordLabel")}
              </a>
            </div>
            <LoginAlert message={loginError} />
          </section>
          <Footer />
        </div>
        <Picture />
      </div>
    </section>
  );
}
const LoginAlert = ({ message }) => {
  return (
    <div className="nw-login--message">
      <span className="nw-alert nw-alert-error">{message}</span>
    </div>
  );
};

const LoginHeader = () => {
  const { t, Trans } = useTranslate();
  return (
    <>
      <h2 className="svh1 nw-login--title">
        {t("loginPage.loginHeaderTitle")}
      </h2>
      <p className="nw-login--desc nws-login--desc">
        <Trans>{t("loginPage.loginHeaderQuote")}</Trans>
      </p>
    </>
  );
};
