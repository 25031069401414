
import { useStore } from '../../store';

export const useTabParam = (closable = false) => {

    const [setTabData, tabData] = useStore((state) => [
        state.SetTabData,
        state.tabData,
    ]);
    const [setActiveKey, activeKey] = useStore((state) => [
        state.setActiveKey,
        state.activeKey,
    ]);
    const [setToggle, collapsed] = useStore((state) => [
        state.setToggle,
        state.collapsed,
    ]);
    const tabParams = [[...tabData], setTabData, setActiveKey, setToggle, closable];

    return { tabParams }
}