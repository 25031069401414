import React from "react";
import { homeDashBoardCardTypes } from "./Constants";
import { DashboardCardContainer } from "./DashboardCardContainer";
import { DashboardCardContent } from "./DashboardCardContent";

export const DashboardCardWithoutChild = ({
  item,
  handleOnClick,
  dashboardData,
  hasChild,
}) => {
  const { iconName, displayLabel, type, shouldShow } = item;

  const getCount = () => {
    switch (type) {
      case homeDashBoardCardTypes.PlanningCompleted:
        return dashboardData.planningCompleted.planningCount;
      case homeDashBoardCardTypes.CurrentYearPlanning:
        return dashboardData.currentYear.currentYearCount;
      case homeDashBoardCardTypes.ProcedureCompleted:
        return dashboardData.procedureCompleted.procedureCompletedCount;
      case homeDashBoardCardTypes.ProcedureInProgress:
        return dashboardData.procedureInProgress.ProcedureInProgressCount;
      case homeDashBoardCardTypes.ProcedureReviewPending:
        return dashboardData.procedureReviewPending.procedureReviewPendingCount;
      case homeDashBoardCardTypes.ProcedureApprovePending:
        return dashboardData.procedureApprovePending
          .procedureApprovePendingCount;
      default:
        return "";
    }
  };

  const isLoading = () => {
    switch (type) {
      case homeDashBoardCardTypes.PlanningCompleted:
        return dashboardData.planningCompleted.PlanningCompletedLoading;
      case homeDashBoardCardTypes.CurrentYearPlanning:
        return dashboardData.currentYear.currentYearCountLoading;
      case homeDashBoardCardTypes.ProcedureCompleted:
        return dashboardData.procedureCompleted.procedureCompletedLoading;
      case homeDashBoardCardTypes.ProcedureInProgress:
        return dashboardData.procedureInProgress.ProcedureInProgressLoading;
      case homeDashBoardCardTypes.ProcedureReviewPending:
        return dashboardData.procedureReviewPending
          .procedureReviewPendingLoading;
      case homeDashBoardCardTypes.ProcedureApprovePending:
        return dashboardData.procedureApprovePending
          .procedureApprovePendingLoading;
      default:
        return false;
    }
  };

  const status = () => {
    switch (type) {
      case homeDashBoardCardTypes.PlanningCompleted:
        return dashboardData.planningCompleted.planningStatus;
      case homeDashBoardCardTypes.CurrentYearPlanning:
        return dashboardData.currentYear.currentYearStatus;
      case homeDashBoardCardTypes.ProcedureCompleted:
        return dashboardData.procedureCompleted.procedureStatus;
      case homeDashBoardCardTypes.ProcedureInProgress:
        return dashboardData.procedureInProgress.ProcedureInProgressStatus;
      case homeDashBoardCardTypes.ProcedureReviewPending:
        return dashboardData.procedureReviewPending
          .procedureReviewPendingStatus;
      case homeDashBoardCardTypes.ProcedureApprovePending:
        return dashboardData.procedureApprovePending
          .procedureApprovePendingStatus;
      default:
        return false;
    }
  };

  return (
    <DashboardCardContainer
      onClick={handleOnClick}
      shouldShow={shouldShow}
      hasChild={hasChild}
    >
      <DashboardCardContent
        iconName={iconName}
        displayLabel={displayLabel}
        count={getCount()}
        isLoading={isLoading()}
        status={status()}
      />
    </DashboardCardContainer>
  );
};
