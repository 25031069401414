import React, { useEffect, useMemo, useRef, useState } from "react";
import { useStore } from "../../store";
import { getappsettings, getMenuList } from "../../Services/Settings.Services";
import { UserDataContext } from "../../Context/UserDataContext";
import { NWSTooltip } from "@nws/nws-ui-library";
import {
  getUserCompanyName,
  getUserLoginData,
  setUserDateFormat,
} from "../../Helpers/user.helper";
import { LogoutSession, permissionWrapper } from "../../Helpers/helper";
import { useHistory } from "react-router-dom";
import { MenuIcon, IconNames } from "@nws/nws-ui-library";
import { useOutsideAlerter } from "../Shared/useOutsideAlerter";
import { MAX_VISIBLE_TABS } from "../../Helpers/constants";

export default function LandingContainer() {
  const router = useHistory();
  const [clearAllStates] = useStore((state) => [state.clearAllStates]);
  const [setActiveKey, activeKey] = useStore((state) => [
    state.setActiveKey,
    state.activeKey,
  ]);
  const [setTabData, tabData] = useStore((state) => [
    state.SetTabData,
    state.tabData,
  ]);
  const [RemoveTabData] = useStore((state) => [state.RemoveTabData]);
  const [SetUserData, userData] = useStore((state) => [
    state.SetUserData,
    state.userData,
  ]);
  const [setDateFormat, dateFormat] = useStore((state) => [
    state.setDateFormat,
    state.dateFormat,
  ]);
  const [setDecimalFormat, decimalFormat] = useStore((state) => [
    state.setDecimalFormat,
    state.decimalFormat,
  ]);
  const [setZCompanyName, z_CompanyName] = useStore((state) => [
    state.setZCompanyName,
    state.z_CompanyName,
  ]);
  const { setUserData } = React.useContext(UserDataContext);
  const [setmenuListArr, menuListArr] = useStore((state) => [
    state.setmenuListArr,
    state.menuListArr,
  ]);

  const [visibleExcessTab, setVisibleExcessTab] = useState(false);
  const onChange = (activeKey) => {
    setActiveKey(activeKey);
  };
  const onEdit = (targetKey, action) => {
    switch (action) {
      case "remove":
        remove(targetKey);
        break;
      case "add":
        add(null, "Empty");
        break;
    }
  };
  const remove = (targetKey) => {
    const panesCopy = tabData.filter((pane) => pane.key !== targetKey);
    setActiveKey(panesCopy[panesCopy.length - 1].key);
    RemoveTabData(panesCopy);
  };

  useEffect(() => {
    if (getUserLoginData() != null) {
      setZCompanyName(getUserCompanyName());
      SetUserData(getUserLoginData());
      //=========Calling company date/decimal formats====//
      getappsettings().then((SettingsList) => {
        if (SettingsList) {
          setDateFormat(SettingsList.dateFormat);
          setUserDateFormat(SettingsList.dateFormat);
          setDecimalFormat(SettingsList.decimalPlace);
          setUserData({ dateFormat: SettingsList.dateFormat });
        }
      });
      getMenuList().then((menuList) => {
        setmenuListArr(menuList);
        permissionWrapper.setMenuList(menuList)
      });
    } else {
      LogoutSession(clearAllStates, router);
    }
  }, []);

  const wrapperRef = useRef(null);
  const [isOpen, setOpen] = useState(false);
  useOutsideAlerter(wrapperRef, () => {
    setOpen(false);
  });

  const getVisibleAndExcessTabs = () => {
    let excessTabs = tabData.slice(MAX_VISIBLE_TABS);
    const visibleTabs = tabData.slice(0, MAX_VISIBLE_TABS);
    if (!visibleTabs.some((x) => x.key === activeKey)) {
      excessTabs = excessTabs.filter((x) => x.key !== activeKey);
      excessTabs.push(visibleTabs[7]);
      visibleTabs[7] = tabData.find((x) => x.key === activeKey);
    }
    return { excessTabs, visibleTabs };
  };

  const { excessTabs, visibleTabs } = useMemo(getVisibleAndExcessTabs);

  useEffect(() => {
    if (excessTabs.length >= 1) {
      setVisibleExcessTab(true);
    } else {
      setVisibleExcessTab(false);
    }
  }, [excessTabs]);

  return (
    <>
      <section className="nw-page-tab ">
        {visibleExcessTab && (
          <div className="tab-dropdown" onClick={() => setOpen(true)}>
            <MenuIcon
              iconName={IconNames.DownArrow}
              height="1rem"
              width="1rem"
              cursor="pointer"
            />

            <div
              className={`nw-header-user-section  ${isOpen === true ? " active" : ""
                }`}
            >
              <div
                className="nw-header-user-section__items tab-drop-down-menu"
                onClick={(e) => e.preventDefault()}
                ref={wrapperRef}
              >
                {excessTabs.map((pane) => (
                  <TabItem
                    title={pane.title}
                    key={pane.key}
                    tabKey={pane.key}
                    onSelect={onChange}
                    onClose={onEdit}
                    isActive={pane.key === activeKey}
                    isDropDown={true}
                  />
                ))}
              </div>
            </div>
          </div>
        )}

        <div className="tab-list">
          {visibleTabs.map((pane) => (
            <TabItem
              title={pane.title}
              key={pane.key}
              tabKey={pane.key}
              onSelect={onChange}
              onClose={onEdit}
              isActive={pane.key === activeKey}
            />
          ))}
        </div>
      </section>
      {tabData.map((pane, index) => {
        let isActive = pane.key === activeKey
        return isActive && <TabBody
          content={pane.content}
          key={"body-" + pane.key}
          index={index}
        />
      })}
    </>
  );
}

const TabItem = ({
  title,
  onClose,

  onSelect,
  isActive,
  tabKey,
  className,
  isDropDown,
}) => {
  const onTabSelect = () => {
    onSelect(tabKey);
  };

  const onTabClose = () => {
    onClose(tabKey, "remove");
  };

  return (
    <div
      className={`${isDropDown ? "tab-drop-down" : "tab-list-item"}${isActive ? " active" : ""
        } `}

    >
      <NWSTooltip title={title}>
        <div className={`tab-list-name `} onClick={onTabSelect}>
          {title.length > 12 ? title.substring(0, 10) + ".." : title}
        </div>
      </NWSTooltip>
      {title !== "Home" && (
        <div className="close" onClick={onTabClose}>
          <svg
            role="img"
            width="1em"
            height="1em"
            fill="currentColor"
            className="sv-icon ico-mag-glass"
          >
            <use xlinkHref="#nw-close"></use>
          </svg>
        </div>
      )}
    </div>
  );
};

const TabBody = ({ content }) => {
  return (
    <section className="tab-body-section">
      {content}
    </section>
  );
};