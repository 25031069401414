import React from "react";
import { menuData } from "./MenuData";
import { MenuItem } from "./MenuItem";
import { useStore } from "../../store";
import { useTranslate } from "../../hooks/translation/useTranslationHook";
import { useState } from "react";
import { permissionWrapper } from "../../Helpers/helper";

export const LHSMenuPanel = () => {
  const [menuSelected, setMenuSelected] = useState({ name: "", menuId: 0 });
  const [setTabData, tabData] = useStore((state) => [
    state.SetTabData,
    state.tabData,
  ]);

  const [setActiveKey, activeKey] = useStore((state) => [
    state.setActiveKey,
    state.activeKey,
  ]);
  const [setToggle, collapsed] = useStore((state) => [
    state.setToggle,
    state.collapsed,
  ]);

  const { t } = useTranslate();

  const hasAccess = (menuId, access) => {
    if (permissionWrapper.permissionData) {
      if (permissionWrapper.permissionData.find((element) => element.menuID === menuId)) {
        return access ? permissionWrapper.permissionData.find((element) => element.menuID === menuId).access : permissionWrapper.permissionData.find((element) => element.menuID === menuId).accessView
      }
    }
    return true;
  };
  
  const buildMenu = (item, level, isLastChild) => {
    return (
      item.isSideBarMenu && hasAccess(item.menuId, item.access) && (
        <MenuItem
          name={item.name}
          tabParams={{
            tabName: t(item.name),
            pageLink: item.linkUrl,
            tabData: tabData,
            setTabData: setTabData,
            setActiveKey: setActiveKey,
            setToggle: setToggle,
            collapsed: collapsed,
          }}
          subMenu={item.children}
          iconName={item.iconName}
          key={item.uniqueKey}
          level={level}
          isLastChild={isLastChild}
          menuSelected={menuSelected}
          setMenuSelected={setMenuSelected}
          className={item.className}
        >
          {item.children && (
            <ul className="nw-main-nav--child-list">
              {item.children.map((child) => {
                return buildMenu(child, level + 1, isLastChild);
              })}
            </ul>
          )}
        </MenuItem>
      )
    );
  };

  return (
    <ul className="nw-main-nav nw-global-nav">
      {menuData.map((item, i) => {
        let isLastChild = menuData.length === i + 1 ? true : false;
        return buildMenu(item, 1, isLastChild);
      })}
    </ul>
  );
};
