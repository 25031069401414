import { useStore } from "../../store";

export const useMenuList = () => {
  const [setmenuListArr, menuListArr] = useStore((state) => [
    state.setmenuListArr,
    state.menuListArr,
  ]);

  return { menuListArr };
};
