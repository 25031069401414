class RefreshCallModel {
    isRefreshTokenInProgress
    constructor() {
        this.isRefreshTokenInProgress = false
    }

    get isRefreshTokenInProgress() {
        return this.isRefreshTokenInProgress;
    }

    set isRefreshTokenInProgress(value) {
        this.isRefreshTokenInProgress = value;
    }
}

let refreshCallModel = new RefreshCallModel()
export default refreshCallModel

