import {
  IconNames,
  NWSButton,
  NWSPopup,
  NWSTextField,
} from "@nws/nws-ui-library";
import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { LogoutSession } from "../../../Helpers/helper";
import { nwstoast, toastType } from "../../../Helpers/NWSToast";
import useAutoLogout from "../../../Helpers/useAutoLogout";
import { getUserName, setUserLoginData } from "../../../Helpers/user.helper";
import { useTranslate } from "../../../hooks/translation/useTranslationHook";
import { authenticate } from "../../../Services/Login.Service";
import { useStore } from "../../../store";
import { IDLE_LOGOUT_TIME } from "../Constants/Timeout.constant";
import {
  GlobalStateContext,
  GlobalStateKeys,
} from "../../../Context/GlobalStateContext";

export const ReloginPopup = () => {
  const { setGlobalState } = useContext(GlobalStateContext);
  const [setIsLoggedIn, isLoggedIn] = useStore((state) => [
    state.setIsLoggedIn,
    state.isLoggedIn,
  ]);
  const { t } = useTranslate();
  const [IdlePopupOpen, setIdlePopupOpen] = useState(false);
  const [clearAllStates] = useStore((state) => [state.clearAllStates]);
  const router = useHistory();
  const [reLoginPassword, setreLoginPassword] = useState("");
  const timer = useAutoLogout(IDLE_LOGOUT_TIME);

  const reLogin = () => {
    if (reLoginPassword.trim().length > 0) {
      authenticate(getUserName(), reLoginPassword).then((response) => {
        try {
          if (response.data && response.data.jwtToken) {
            setIsLoggedIn(true);
            setUserLoginData(response.data);
            setGlobalState(GlobalStateKeys.ReloginPopupStatus, {
              reloginStatus: false,
            });
            setIdlePopupOpen(false);
          }
        } catch (error) {
          nwstoast(t("relogin.reloginPassword"), toastType.Error);
        }
      });
    } else {
      nwstoast(t("commonToast.passwordError"), toastType.Error);
    }
  };

  const enterSubmit = (event) => {
    if (event.key === "Enter") {
      reLogin(event);
    }
  };

  //==============Handle Idle timeout============//
  useEffect(() => {
    if (timer === 0 && !IdlePopupOpen) {
      setIdlePopupOpen(true);
      setGlobalState(GlobalStateKeys.ReloginPopupStatus, {
        reloginStatus: true,
      });
      setreLoginPassword("");
    }
  }, [timer, IdlePopupOpen]);

  return (
    <NWSPopup
      open={IdlePopupOpen}
      title={t("loginPage.login")}
      width="sm"
      hasCloseButton={false}
      className="relogin-popup"
      body={
        <NWSTextField
          id="Password"
          label={t("commonLabels.password")}
          isRequired={true}
          type="password"
          onChange={(e) => {
            setreLoginPassword(e.target.value);
          }}
          value={reLoginPassword}
          onKeyDown={enterSubmit}
        />
      }
      footer={
        <>
          <NWSButton
            color="secondary"
            label={t("loginPage.login")}
            iconName={IconNames.Apply}
            onClick={reLogin}
          />
          <NWSButton
            color="secondary"
            label={t("commonLabels.logout")}
            iconName={IconNames.SignOut}
            onClick={() => LogoutSession(clearAllStates, router)}
          />
        </>
      }
    />
  );
};
