export const taskInvoiceGridColumns = (t) => [
  {
    title: t("commonLabels.client"),
    dataIndex: "client",
    width: "30%",
  },
  {
    title: t("commonLabels.description"),
    dataIndex: "description",
    width: "30%",
  },
  {
    title: t("commonLabels.status"),
    dataIndex: "status",
    width: "30%",
  },
];
