import httpHelper from "./HttpServiceUtils/HttpHelper";
import { loggerhelper } from "./LoggerService/LoggerHelper";
import { PageNames } from './LoggerService/PageNames';

export const getInvoiceListData = async (clientName,fromDate,toDate,pageNumber,pageSize) => {
    try {
        loggerhelper.trace(PageNames.INVOICE, `Get Invoice List Data`, `from FinancialAudit|Invoice `);
        const response = await httpHelper.get(`Invoice/search?ClientName=${clientName}&FromDate=${fromDate}&ToDate=${toDate}&paginationQuery.PageNumber=${pageNumber}&paginationQuery.PageSize=${pageSize}`);
        loggerhelper.trace(PageNames.INVOICE, `Get Invoice List Data`, `from FinancialAudit|Invoice`);
        return { response };
    } catch (err) {
        loggerhelper.error(err);
    }
};

export const saveInvoiceData = async (id,data) => {
    try {
        loggerhelper.trace(PageNames.INVOICE, `Save Invoice Data`, `from FinancialAudit|Invoice `);
        if(id == 0){
            const response = await httpHelper.post('/invoice', data);
            return { response };
        }else {
            const response = await httpHelper.put('/invoice', data);
            loggerhelper.trace(PageNames.INVOICE, `Save Invoice Data`, `from FinancialAudit|Invoice`);
            return { response }; 
        }   
    } catch (err) {
        loggerhelper.error(err);
    }
};

export const cancelInvoiceData = async (id) => {
    try {
        loggerhelper.trace(PageNames.INVOICE, `Cancel Invoice Data`, `from FinancialAudit|Invoice `);
        const response = await httpHelper.put(`/invoice/isCancel/${id}`);
        loggerhelper.trace(PageNames.INVOICE, `Cancel Invoice Data`, `from FinancialAudit|Invoice`);
        return { response };
    } catch (err) {
        loggerhelper.error(err);
    }
};

export const approveInvoiceData = async (id,data) => {
    try {
        loggerhelper.trace(PageNames.INVOICE, `Approve Invoice Data`, `from FinancialAudit|Invoice `);
        const response = await httpHelper.put(`/invoice/approval/${id}`,data);
        loggerhelper.trace(PageNames.INVOICE, `Approve Invoice Data`, `from FinancialAudit|Invoice`);
        return { response };
    } catch (err) {
        loggerhelper.error(err);
    }
}

export const getInvoiceData = async (id) => {
    try {
        loggerhelper.trace(PageNames.INVOICE, `Get Invoice Data`, `from FinancialAudit|Invoice `);
        const response = await httpHelper.get(`/invoice/${id}`);
        loggerhelper.trace(PageNames.INVOICE, `Get Invoice Data`, `from FinancialAudit|Invoice`);
        return response;
    } catch (err) {
        loggerhelper.error(err);
    }
}

export const saveReceiptData = async (data) => {
    try {
        loggerhelper.trace(PageNames.INVOICE, `Save Receipt Data`, `from FinancialAudit|Invoice `);
        const response = await httpHelper.put('/invoice/receipt', data);
        loggerhelper.trace(PageNames.INVOICE, `Save Receipt Data`, `from FinancialAudit|Invoice`);
        return { response };
    } catch (err) {
        loggerhelper.error(err);
    }
}

export const getInvoiceReceiptSummary = async (fiscalYearId) => {
    try {
        const response = await httpHelper.get(`/invoice/summary/${fiscalYearId}`);
        return { response };
    } catch (err) {
        loggerhelper.error(err);
    }
}