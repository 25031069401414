import { IconNames, MenuIcon } from "@nws/nws-ui-library";
import React from "react";
import { useTranslate } from "../../hooks/translation/useTranslationHook";

export const ImageUpload = ({
  handleChange,
  file,
  menuHeight,
  menuWidth,
  hasPreviewImage = true,
  accept, multiple
}) => {
  const { t } = useTranslate();
  
  return (
    <div className="nws-login--header">
      <input
        accept={accept || "image/*"}
        id="contained-button-file"
        multiple={multiple}
        type="file"
        onChange={handleChange}
        style={{ display: "none" }}
      />
      <label htmlFor="contained-button-file">
        {hasPreviewImage && (
          <img
            width="100"
            height="100"
            src={file}
            id="contained-button-file"
            className="nw-img-avatar"
          />
        )}
        <div className="nw-cpm-upload nw-img-upload">
          <div className="nw-cpm-trials">
            <div className="nw-cpm-drag-user-profile">
              <div className="nw-cpm-dragArea">
                <MenuIcon
                  iconName={IconNames.UploadFile}
                  height={menuHeight}
                  width={menuWidth}
                />
                <span>{t("commonLabels.clickToBrowse")}</span>
              </div>
            </div>
          </div>
        </div>
      </label>
    </div>
  );
};
