export const PageNames = {
  LOGIN: "Login",
  HOME: "Home",
  CLIENT: "Client",
  USER: "User",
  SETTING: "Setting",
  ROLE: "Role",
  COMPANY: "Company",
  ENGAGEMENT: "Engagement",
  ENGAGEMENTCONTINUES: "Engagementcontinues",
  ENGAGEMENTCONTINUESSETTING: "Engagementcontinuessetting",
  ENGAGEMENTSETTING: "Engagementsetting",
  COMMONSERVICE: "Commonservice",
  CONTROLOWNER: "ControlOwner",
  PROJECT: "Project",
  SENDMAIL: "Sendmail",
  FASCHEDULE: "Faschedule",
  RASCHEDULE: "Raschedule",
  RISKCATEGORY: "Riskcategory",
  PROCEDURE: "Procedure",
  PLANNING: "Planning",
  RISKEVALUATION: "Riskevaluation",
  RISKANALYSISEVALUATION: "RiskAnalysisEvaluation",
  CHANGEPASSWORD: "ChangePassword",
  CLIENTFILEUPLOAD: "ClientUploadFile",
  SAMPLEFILEDOWNLOAD: "SampleFileDownload",
  FILEPROCESSINGSUMMARY: "FileProcessingSummary",
  FILEPROCESSINGTRIALBALANCESUMMARY: "FileProcessingTrialBalanceSummary",
  TRIALBALANCEORLEDGERFILELIST: "TrialBalanceOrLedgerFileList",
  TRIALBALANCEACTIONREQUIRED: "TrialBalanceActionRequired",
  CLIENTDASHBOARDMESSAGE: "ClientDashboardMessage",
  LEDGERUPLOADFILESTATUS: "LedgerFileUploadDetails",
  CLIENTDASHBOARD: "ClientDashboard",
  DASHBOARD: "Dashboard",
  LEDGERDETAILS: "LedgerDetails",
  COMPLIANCEAUDIT: "ComplianceAudit",
  PARTNERCHECKLIST: "PartnerChecklist",
  IFRSCHECKLIST: "IfrsChecklist",
  FINALREVIEW: "FinalReviewChecklist",
  AUDITOPTION: "AuditOptionChecklist",
  REPRESENTATIONLETTER: "RepresentationLetter",
  SPECIFICREPRESENTATIONLETTER: "SpecificRepresentationLetter",
  REPORTSETTINGS: "ReportSettings",
  INVOICE: "Invoice",
  ROLE: "Role",
  USER: "User",
  TASKMANAGEMENTPROJECT: "Task Management Project",
  DOCUMENTMANAGMENT: "Document Management",
  NOTES: "Notes",
  FACOMPANYACT: "Fixed Asset Company Act",
  FAINCOMETAX: "Fixed Asset Income Tax",
  CONTACT: "contact",
  LEAD: "lead",
  EMPLOYEESETTINGS: "employeeSettings",
  EMAIL: "Email",
  CONTACT: "Contact",
  LEAD: "Lead",
  EMAIL: "Email",
  CLIENTSETTINGS: "clientSettings",
  TEAMS: "Teams",
  NoteReportSettings: "noteReportSettings",
  ClientCredentialMgr: "ClientCredentialMgr",
  GRADE: "gradeMapping",
  AUDITREPORTSETTINGS: "auditReportSettings",
  AUDITREPORT: "auditReport",
  DIRECTORSREPORTSETTINGS: "directorReportSettings",
  DIRECTORREPORT: "directorReport",
  RATIOANALYSISREPORT: "ratioAnalysisReport",
  CASHFLOWREPORT: "cashFlowReport",
  FIXEDASSETREGISTER: "fixedAssetRegister",
  ENGAGEMENTCONTINUOUS: "engagementContinuous",
  MATERIALITYREVIEW: "materialityReview",
  PLANNINGSIGNOFF: "planningSignOff",
  MATERIALITYRANGE: "materialityRange",
  MATERIALITY: "materiality",
  COMPANYINFO: "companyInfo",
  AUDITOPERATINGINFO: "auditOperatingInfo",
  LAWSANDREGULATIONS: "lawsAndRegulations",
  TAXATIONDETAILS: "taxationDetails",
  UNDERSTANDINGCLIENTBUSINESS: "understandingClientBusiness",
  RELATEDPARTIES: "relatedParties",
  MATERIALITYRANGE: "materialityRange",
  RISKASSESSMENT: "riskAssessment",
  STATUTORYINFO: "statutoryInfo",
  FINANCIALSTATEMENTS: "financialStatements",
  AUDITSIGNOFF: "auditSignOff",
  GOINGCONCERN: "goingConcern",
  ACCOUNTINGSTANDARDS: "accountingStandars",
  PROCEDUREPROFITANDLOSS: "procedureProfitAndLoss",
  PROCEDUREBALANCESHEET: "procedureBalanceSheet",
  PARTNERMANAGERREVIEW: "partnerManagerReview",
  SUBSTANTIVEPROCEDURE:"substantiveProcedure",
  OBSERVATIONDISPOSAL: "observationDisposal",
  STAFFASSIGNMENT: "staffAssignment",
  ACCOUNTGROUPING:"accountGrouping"
};
