import { loginHttpHelper } from "./HttpServiceUtils/HttpHelper";
import { loggerhelper } from "./LoggerService/LoggerHelper";

export const userChangePassword = async (data) => {
    try {
        const response = await loginHttpHelper.put("/Users/password", data);
        return { response };
    } catch (err) {
        loggerhelper.error(err);
    }

};

export const companyChangePassword = async (data) => {
    try {
        const response = await loginHttpHelper.put("/Company/emailPassword", data);
        return { response };
    } catch (err) {
        loggerhelper.error(err);
    }

};