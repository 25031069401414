import { loginHttpHelper } from "./HttpServiceUtils/HttpHelper";
import { loggerhelper } from "./LoggerService/LoggerHelper";
import { PageNames } from './LoggerService/PageNames';

const LOGIN_END_POINT = "/login";

/**
 * Function to authenticate a user upon login using email and password
 * @param {String} email 
 * @param {String} password 
 */
export const authenticate = async (email, password) => {
  try {
    loggerhelper.info(PageNames.LOGIN, `Authenticating: ${email}`);
    const payload = {
      username: email,
      password: password,
    };
    const isResponsePreviewHide = true;
    const response = await loginHttpHelper.post(LOGIN_END_POINT, payload, true, isResponsePreviewHide)
    loggerhelper.trace(PageNames.LOGIN, `Authenticated ${email} with status code ${response.status}`);

    return response;
  } catch (err) {
    loggerhelper.error(err)
  }
};


export const getRole = async (roleId, isForRoleSetting = false) => {
  try {
    loggerhelper.info(
      PageNames.ROLE,
      `Fetch RoleID`,
      `from Users|Role `,
      `to get${roleId}`,
    );
    const result = await loginHttpHelper.get(
      `/Role/menuwise/${roleId}/${isForRoleSetting}`,
    );
    loggerhelper.trace(
      PageNames.ROLE,
      `Get Role from' ,' from  Users|Role`,
      `to get${roleId}`,
    );
    return { result };
  } catch (err) {
    loggerhelper.error(err);
  }
};