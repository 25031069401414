export const DEFAULT_DATE_FORMAT = 'dd/mm/yyyy'
export const MAX_VISIBLE_TABS = 8;
export const defaultListObj = (isCustom = false, isSelect = false,isString=false, idKey, valueKey, id, value) => {
    if (isCustom) {
        return { [idKey]: id, [valueKey]: value }
    }
    else {
        if(isString)
        {
            return isSelect ? { id: "0", value: '--Select--' } : { id: "0", value: '--All--' }
        }
        else{
            return isSelect ? { id: 0, value: '--Select--' } : { id: 0, value: '--All--' }
        }
        
    }
}

export const ddButtonListDefaultValue=(displayText)=>{return{ id: 0, value: displayText }}

export const actionList=[
    { id: 0, value: "--Select--" },
    { id: true, value: "Yes/No" },
    { id: false, value: "NA" },
  ]

  export const actionRiskList=[
    { id: "0", value: "--Select--" },
    { id: "High", value: "High" },
    { id: "Moderate", value: "Moderate" },
    { id: "Low", value: "Low" },
  ]

  export const docFileTypes=".doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.xls,.xlsx,.pdf,.jpeg,.jpg,.txt,.png,.rtf";

  export const popupSize = {
    large: "lg",
    medium: "md",
    small: "sm",
  };

  export const NWSTextFieldLimit = {
    Thousand:"1000"
  }