import { IconNames, NWSButton } from "@nws/nws-ui-library";
import React from "react";
import { useTranslate } from "../../hooks/translation/useTranslationHook";

export const FooterButton = ({
  onDiscard,
  onSave,
  type,
  iconType,
  buttonLabel,
}) => {
  const { t } = useTranslate();
  return (
    <section>
      <div></div>
      <div className="filter__right">
        {onDiscard && (
          <NWSButton
            color="secondary"
            iconName={IconNames.Discard}
            onClick={onDiscard}
            label={t("commonLabels.discard")}
          ></NWSButton>
        )}
        <NWSButton
          color="primary"
          iconName={type == "Save" ? IconNames.Save : IconNames.Apply}
          onClick={onSave}
          label={
            buttonLabel ||
            (type == "Save"
              ? t("commonLabels.saveLabel")
              : t("commonLabels.applyLabel"))
          }
        ></NWSButton>
      </div>
    </section>
  );
};
