import React, { createContext, useState } from "react";

export const ClientStateContext = createContext();

/**
 * This is the Client state object and method to set the client state
 */
export const ClientStateContextProvider = (props) => {
    const [clientState, setState] = useState({});

    const setClientState = (clientId, key, payload) => {
        const _clientState = { ...clientState }
        if (!_clientState[clientId]) {
            _clientState[clientId] = {}
        }
        _clientState[clientId][key] = payload

        setState(_clientState)
    }

    const setClientStateMany = (clientId, keyArray, payloadArray) => {
      const _clientState = { ...clientState };
      if (!_clientState[clientId]) {
        _clientState[clientId] = {};
      }
      for (let i = 0; i < keyArray.length; i++) {
        _clientState[clientId][keyArray[i]] = payloadArray[i];
      }

      setState(_clientState);
    };

    return (
        <ClientStateContext.Provider
            value={{
                clientState,
                setClientState,
                setClientStateMany
            }}
        >
            {props.children}
        </ClientStateContext.Provider>
    );
};


export const ClientStateKeys = {
    ClientAccountAnanlysisData: "clientAccountAnanlysisData",
    Planning:"planning",
    LedgerDetailsData: "ledgerDetailsData",
    EngagementContinousList:"engagementContinousList",
    NoteReportSettings:"noteReportSettings",
    ReportAdditonalFieldSettings: "reportAdditonalFieldSettings",
    FixedAssetITSettings: "fixedAssetItSettings", 
    FixedAssetCompanyAct: "fixedAssetCompanyAct",
    AccountGrouping: "accountGrouping", 
    ReportNotes: "reportNotes",
    AuditReport:"auditReport",
    DirectorReport:"directorReport",
    RatioAnalysisSettings: "ratioAnalysisSettings",
    CaroReport:"caroReport",
    FixedAssetRegisterSettings:"fixedAssetRegisterSettings",
}

export const ClientDashboardV2Keys = {
    Materiality: "materiality",
    ClientDashboardProgressStatus:"clientDashboardProgressStatus",
    TrialBalance:"trialBalance",
    Materiality: "materiality",
    EngagementContinuous:"engagementContinuous",
    MaterialityChecklist: "materialityChecklist",
    TaskAllocation:"taskAllocation",
    PlanningSignOff:"planningSignOff",
    Materiality:"materiality",
    StatutoryInfoChecklist: "statutoryInfoChecklist",
    CompanyInfoChecklist: "companyInfoChecklist",
    AuditOperatingInfoChecklist: "auditOperatingInfoChecklist",
    LawsAndRegulationsChecklist: "lawsAndRegulationsChecklist",
    TaxationDetailsChecklist: "taxationDetailsChecklist",
    UnderstandingClientBusinessChecklist: "understandingClientBusinessChecklist",
    RelatedPartiesChecklist: "relatedPartiesChecklist",
    FixedAssetRegister: "fixedAssetRegister",
    ClientDashboardDetails:"clientDashboardDetails",
    FinancialStatements:"financialStatements",
    GoingConcernChecklist:"goingConcernChecklist",
    RiskAssessmentChecklist:"riskAssessmentChecklist",
    AuditSignOffChecklist:"auditSignOffChecklist",
    ClientObservationDisposal: "clientObservationDisposal",
    EngagementsChecklist: "engagementsChecklist",
    ProfitAndLoss: "profitAndLoss",
    AccountingStandard:"accountingStandard",
    ProcedureProfitAndLoss: "procedureProfitAndLoss",
    BalanceSheet: "balanceSheet",
    SubstantiveProcedureChecklist:"substantiveProcedureChecklist",
    ProcedureBalanceSheet: "procedureBalanceSheet",
    PartnerManagerReview: "partnerManagerReview",
    FilterCriteria:"filterCriteria",
    LedgerDetailData:"ledgerDetailData",
    StaffAssignmentChecklist: "staffAssignmentChecklist",
    StaffAssignment: "staffAssignment",
    TeamDiscussion: "teamDiscussion"
}