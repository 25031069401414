import { toast } from 'react-toastify';

export const nwstoast = (message, type, timeOut) => {
    let toastObj = {
        position: "top-center",
        autoClose: timeOut || 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme:'colored'
    }
    switch (type) {
        case toastType.Success:
            toast.success(message, toastObj);
            break;
        case toastType.Info:
            toast.info(message, toastObj);
            break;
        case toastType.Warning:
            toast.warning(message, toastObj);
            break;
        case toastType.Error:
            toast.error(message, toastObj);
            break;
        default:
            toast(message, toastObj);
            break;
    }

}

export const toastType = {
    Success: 'Success',
    Info: 'Info',
    Warning: 'Warning',
    Error: 'Error',
}
