import React from 'react'
import dynamic from "next/dynamic";
import dayjs from 'dayjs'
import { getUserDisplayDateFormat } from './user.helper';
import { getCompany } from '../Services/Company.Service';
import RichTextEditorLoadingSpinner from '../components/Shared/RichTextEditorLoadingSpinner';
import Xlsx from 'xlsx';
import refreshCallModel from '../Services/HttpServiceUtils/RefreshCallModel';
import { menuData } from '../components/Menu/MenuData';

export let addNewtabwindow = (Screenname, Screenlink, tabData, setTabData, setActiveKey, setToggle, collapsed, recordID) => {
  const getDynamicComponent = (c) => dynamic(() => import(`../components/${Screenlink}`), {
    ssr: false,
    loading: () => <RichTextEditorLoadingSpinner />,
  });

  const { component } = Screenname;
  const DynamicComponent = getDynamicComponent(component);
  var isExistTab = false;
  tabData.forEach(element => {
    if (element.key == Screenname) {
      isExistTab = true;
    }
  });
  if (!isExistTab) {
    setTabData({ title: Screenname, content: <DynamicComponent recordID={recordID} />, key: Screenname, closable: true, menuId: recordID && recordID.menuId || tabData[tabData.length - 1].menuId });
  }
  setActiveKey(Screenname)
}

export let addNewtabwindowSaveEdit = (Screenname, ScreenLabel, Screenlink, tabData, setTabData, setActiveKey, setToggle, collapsed, recordID) => {
  const getDynamicComponent = (c) => dynamic(() => import(`../components/${Screenlink}`), {
    ssr: false,
    loading: () => <div className="lds-ripple" style={{ margin: '15% 45%' }}><div></div><div></div></div>,
  });

  const { component } = Screenname;
  const DynamicComponent = getDynamicComponent(component);
  var isExistTab = false;
  tabData.forEach(element => {
    if (element.key == ScreenLabel) {
      isExistTab = true;
    }
  });

  if (!isExistTab) {
    setTabData({ title: Screenname, content: <DynamicComponent recordID={recordID} />, key: ScreenLabel, closable: true });
  }
  setActiveKey(ScreenLabel);
}

class PermissionWrapper {

  permissionData;

  setMenuList(arr) {
    this.permissionData = arr;
  }

  checkActionPermision = (menuID, actionCode) => {
    let result = false;
    this.permissionData.forEach((element) => {
      if (element.menuID && element.menuID === menuID) {
        result = checkPermissionWithLevel(actionCode, element, menuID);
        return false;
      }
      element.children &&
        element.children.forEach((childElement) => {
          if (childElement.menuID && childElement.menuID === menuID) {
            result = checkPermissionWithLevel(actionCode, childElement, menuID);
            return false;
          }
          childElement.children &&
            childElement.children.forEach((subChildElement) => {
              if (subChildElement.menuID && subChildElement.menuID === menuID) {
                result = checkPermissionWithLevel(
                  actionCode,
                  subChildElement,
                  menuID,
                );
                return false;
              }
              subChildElement.children &&
                subChildElement.children.forEach((childOfSubChildElement) => {
                  if (
                    childOfSubChildElement.menuID &&
                    childOfSubChildElement.menuID === menuID
                  ) {
                    result = checkPermissionWithLevel(
                      actionCode,
                      childOfSubChildElement,
                      menuID,
                    );
                    return false;
                  }
                });
            });
        });
    });

    return result;
  };

}

let permissionWrapper = new PermissionWrapper()
export { permissionWrapper }


//////need to remove this function after refractoring work
export let checkActionPermision = (menuListArr, menuID, actionCode,resultType) => {
  let result = resultType || false;

  menuListArr.forEach((element) => {
    if (element.menuID && element.menuID === menuID) {
      result = checkPermissionWithLevel(actionCode, element, menuID);
      return false;
    }
    element.children &&
      element.children.forEach((childElement) => {
        if (childElement.menuID && childElement.menuID === menuID) {
          result = checkPermissionWithLevel(actionCode, childElement, menuID);
          return false;
        }
        childElement.children &&
          childElement.children.forEach((subChildElement) => {
            if (subChildElement.menuID && subChildElement.menuID === menuID) {
              result = checkPermissionWithLevel(
                actionCode,
                subChildElement,
                menuID,
              );
              return false;
            }
            subChildElement.children &&
              subChildElement.children.forEach((childOfSubChildElement) => {
                if (
                  childOfSubChildElement.menuID &&
                  childOfSubChildElement.menuID === menuID
                ) {
                  result = checkPermissionWithLevel(
                    actionCode,
                    childOfSubChildElement,
                    menuID,
                  );
                  return false;
                }
              });
          });
      });
  });

  return result;
};
//////////////////////////////////////////////////////////

const checkPermissionWithLevel = (actionCode, selectedChildMenu, menuID) => {
  let result = false;
  if (selectedChildMenu) {
    switch (actionCode) {
      case accessLevels.AccessAdd:
        result = selectedChildMenu.accessAdd;
        break;

      case accessLevels.AccessEdit:
        result = selectedChildMenu.accessEdit;
        break;

      case accessLevels.AccessView:
        result = selectedChildMenu.accessView;
        break;

      case accessLevels.AccessPrint:
        result = selectedChildMenu.accessPrint;
        break;

      case accessLevels.AccessApproval:
        result = selectedChildMenu.accessApproval;
        break;

      case accessLevels.AccessTask:
        result = children.filter((Menulist) => Menulist.menuID === menuID)[0]
          .accessTask;
        break;

      default:
        break;
    }
  }

  return result;
};

export const accessLevels = {
  AccessAdd: 1,
  AccessEdit: 2,
  AccessView: 3,
  AccessPrint: 4,
  AccessApproval: 5,
  AccessTask: 6,
}


export const LogoutSession = (clearState, router) => {
  refreshCallModel.isRefreshTokenInProgress = false
  window.localStorage.clear();
  sessionStorage.removeItem("userData");
  localStorage.removeItem('userDateFormat')
  router.push("./")
  clearState();
  window.location.reload();
}



export let validateEmail = (email) => {
  var re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return re.test(email);
}

export let validatePhoneNumber = (input_str) => {
  var re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
  return re.test(input_str);
}

export let getCompanyHeader = async (ClientName) => {


  let headResult = await getCompany()
    .then(function (response) {
      if (response.data) {
        return "<div style='border:1px solid #e4e4e4;text-align:center;width:100%'><h2>" + response.data.name + "</h2></div><p>To</p><p style='border:width:100%'><i>" + ClientName + "</i></p>";
      }

    })
    .catch(function (error) {
    });
  return (headResult)
}


export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}


export const base64ToBlob = (base64) => {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; ++i) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return new Blob([bytes], { type: 'application/pdf' });
};

export const formatDisplayDate = (value) => {
  const dateFormat = getUserDisplayDateFormat();
  return (value === null || value === undefined) ? "-" : dayjs(value).format(dateFormat);
}

export const formatDateWithoutSeconds = (date) => {
  const modifyDate = new Date(date);
  const year = modifyDate.getFullYear();
  const month = String(modifyDate.getMonth() + 1).padStart(2, "0");
  const day = String(modifyDate.getDate()).padStart(2, "0");
  const hours = String(modifyDate.getHours()).padStart(2, "0");
  const minutes = String(modifyDate.getMinutes()).padStart(2, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}:00.00`;
};

export const downloadFileFromStream = (data, fileName) => {
  var blob = new Blob([data], { type: "application/octet-stream" });
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.href = url;
  link.download = fileName || 'Sample.xlsx';
  link.click();
}

export const removeDuplicates = (duplicatedContent, selectedColumn) => {
  var filteredData = [];
  var lookupItem = {};

  for (var i in duplicatedContent) {
    lookupItem[duplicatedContent[i][selectedColumn]] = duplicatedContent[i];
  }

  for (i in lookupItem) {
    filteredData.push(lookupItem[i]);
  }
  return filteredData;
}

export const excelExport = (data, fileName) => {
  const workSheet = Xlsx.utils.json_to_sheet(data);
  const workBook = Xlsx.utils.book_new();
  Xlsx.utils.book_append_sheet(workBook, workSheet, 'sheet');
  Xlsx.writeFile(workBook, fileName);
};

export const validateEmptyRichTextBox = (value) => {
  var regex = /(<([^>]+)>)/gi;
  const hasText = !!value.replace(regex, "").length;
  return hasText
}

export const titleCheckListDuplicateCheck = (PlanningData) => {
  const titleData = PlanningData.map((item) => {
    return item.title.toLowerCase();
  });

  const getRepeatingTitles = (data) => {
    const trimmedData = data.map((title) => title.trim());
    const titleCountMap = trimmedData.reduce((acc, title) => {
      acc[title] = (acc[title] || 0) + 1;
      return acc;
    }, {});
    
    return Object.keys(titleCountMap).filter((title) => titleCountMap[title] > 1);
   
  };

  const repeatingTitles = getRepeatingTitles(titleData);

  return repeatingTitles.length > 0 ? repeatingTitles : null;
};

export const handleElementScrolling = (ref) => ref.current.scrollIntoView({ behavior: 'smooth' })

export const getMenuIdByActiveKey = (tabData, activeKey) => {
  return tabData.find((currentTab) => currentTab.title === activeKey)?.menuId
}

export const getMenuIdByKey = (key) => {
  return getMenuIdReursion(key, menuData)
}

//TODO: incomplete, complete this and replace
const getMenuIdReursion = (key, menu) => {
  var result;
  for (var item in menu) {
    if (item.uniqueKey == key) {
      return item
    } else if (item.children) {
      result = getMenuIdReursion(item.children)
      if (result) {
        return result
      }
    }
  }
  return result;
}

export const formatDecimalWithoutRounding = (value, decimalValue) => {
  return (
    Math.trunc(value * Math.pow(10, decimalValue)) /
    Math.pow(10, decimalValue)
  );
};

export const formateNumberWithCommas = (value) => {
  return parseFloat(parseFloat(value)).toLocaleString("en-IN", {
    useGrouping: true,
  });
};

export const convertNestedObjectToArray = (nestedObj) =>
  Object.keys(nestedObj).map((key) => nestedObj[key]);

export const customAccountNameEncode = (accountName) =>{
    let encodedAccountName = encodeURIComponent(accountName);
    return encodedAccountName;
  }

export const numberOnlyRegex = (value) => {
    return value.replace(/[^0-9.]/g, "")
  }

export const getYearFromDate = (dateString) => {
  const dateObject = new Date(dateString);
  const year = dateObject.getFullYear();

  return year;
};  

export const getDuplicateTitleInArray = (
  titleKeyName,
  data,
  titleToCheck,
  index
) => {
  if (
    typeof titleKeyName !== "string" ||
    !Array.isArray(data) ||
    typeof titleToCheck !== "string" ||
    typeof index !== "number" ||
    index < 0 ||
    index >= data.length
  ) {
    return false;
  }

  const duplicateTitle = data.find(
    (item, i) => i !== index && item[titleKeyName] === titleToCheck
  );
  return duplicateTitle ? duplicateTitle[titleKeyName] : false;
};

export const isFeatureFlagOn = (featureFlags, uniqueCode) => {
  return featureFlags.some(
    (flag) => flag.uniqueCode === uniqueCode && flag.isOn
  );
};
