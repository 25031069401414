import { NWSMessage } from "@nws/nws-message";
import React from "react";
import { getUserCompanyId, getUserName } from "../../Helpers/user.helper";
import {
  displayMessage,
  fetchMessageInbox,
  fetchMessageOutbox,
  getInboxFromAllClients,
  getInboxUnreadCountFromAllClients,
  sendMessage,
  viewedMessage,
} from "../../Services/Message.Services";
import { messageListData } from "../FinancialAuditing/Clients/shared/MessageListData";

export const NotificationMessage = ({ open, onClose,setMessageCount }) => {
  return (
    <>
      {open && (
        <NWSMessage
          popUpClassName={"msg-dialogbox-popup"}
          sendMessage={sendMessage}
          messageColumn={messageListData}
          displayMessage={displayMessage}
          messageSeen={viewedMessage}
          open={open}
          onClose={onClose}
          isForHomeDashboard={true}
          messageSendBody={{
            fromID: getUserCompanyId(),
            fromUser: getUserName(),
            parentMessageID: 0,
            isFromCompany: true,
            recipientID: 0,
            toUser: 0,
            companyId:getUserCompanyId()
          }}
          getInbox={fetchMessageInbox}
          getOutbox={fetchMessageOutbox}
          getInboxFromAllClients={getInboxFromAllClients}
          getInboxUnreadCountFromAllClients={getInboxUnreadCountFromAllClients}
          setMessageCount={setMessageCount}
        />
      )}
    </>
  );
};
