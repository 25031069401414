export const apiURLs = {

    // PROD URLS
    
    BaseUrl: "https://api.networthsoft.in:4502/api",
    LoginUrl: "https://api.networthsoft.in:4506/api",
    MessageApi: "https://api.networthsoft.in:4508/api",
    TaskManagementApi: "https://api.networthsoft.in:4510/api"

    //DEMO URLS
    
    // BaseUrl: "https://demo--api.networthsoft.in/api",
    // LoginUrl: "https://api.networthsoft.in:4506/api",
    // MessageApi: "https://api.networthsoft.in:4508/api",
    // TaskManagementApi: "https://api.networthsoft.in:4510/api"


    // DEV URLS
    // BaseUrl: "https://dev-api.networthsoft.in:4211/api",
    // LoginUrl: "https://dev-api.networthsoft.in/api",
    // MessageApi: "https://dev-api.networthsoft.in:4561/api",
    // TaskManagementApi: "https://dev-api.networthsoft.in:4571/api"


    // OLD ONES Keeping for references only, DO NOT USE

    // BaseUrl: "https://172.16.16.10:4211/api",
    // LoginUrl: "https://172.16.16.10:443/api",
    // MessageApi: "https://172.16.16.10:4561/api",
    // TaskManagementApi: "https://172.16.16.10:4571/api"
}

export const responseTypes = {
    NoContent: 204,
    Success: 200,
    Unauthorized: 401
}

export const excludeRefreshTokenAPI = [
    'api/login'
]