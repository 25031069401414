import { getCountryId, getUserLoginData } from "../Helpers/user.helper";
import { loginHttpHelper } from "./HttpServiceUtils/HttpHelper";
import { loggerhelper } from "./LoggerService/LoggerHelper";
import { PageNames } from './LoggerService/PageNames';

export const getappsettings = async () => {
  try {
    loggerhelper.trace(PageNames.SETTING, `Get Settings details`, `from Users|Setting `);
    const response = await loginHttpHelper.get("/AppSettings");
    const SettingsList = response.data && getCountryId() && response.data.find((item) => {
      return item.countryID === getCountryId();
    });
    loggerhelper.trace(PageNames.SETTING, `Get Settings details`, `from Users|Setting `);
    return SettingsList;
  } catch (err) {
    loggerhelper.error(err);
  }
};

export const getMenuList = async (isForRoleSetting = false) => {
  try {
    loggerhelper.trace(PageNames.SETTING, `Get Settings details`, `from Menu|Setting `);
    const response = await loginHttpHelper.get('/role/menuwise/' + getUserLoginData().roleID + '/' + isForRoleSetting);
    const menuList = response.data;
    loggerhelper.trace(PageNames.SETTING, `Get Settings details`, `from Menu|Setting `);
    return menuList;
  } catch (err) {
    loggerhelper.error(err);
  }
};

export const getUserByID = async (id) => {
  try {
    loggerhelper.trace(PageNames.SETTING, `Get Settings details`, `from Menu|Setting `);
    const response = await loginHttpHelper.get('Users/' + id);
    loggerhelper.trace(PageNames.SETTING, `Get Settings details`, `from Menu|Setting `);
    return { response };
  } catch (err) {
    loggerhelper.error(err);
  }
};