import React, { createContext, useState } from "react";

export const ClientDashboardContext = createContext();

/**
 * Context to keep the client specific data .
 */
export const ClientDashboardContextProvider = (props) => {
  const [fiscalYear, setFiscalYear] = useState(defaultFiscalYearObject);
  const [letterData, setLetterData] = useState(letterDataDefault);
  const [messageSendData, setMessageSendData] = useState(messageSendDataDefault);
  const [procedureEditData, setProcedureEditData] = useState(procedureEditDataDefault);
  const [ledgerDetailData, setLedgerDetailData] = useState(ledgerDeatilsDefault);
  const [auditGroupId, setAuditGroupId] = useState([]);

  const addComplianceAuditGroupId = (payLoad) => {
    setAuditGroupId([...auditGroupId.filter(x => x.clientId != payLoad.clientId), payLoad]);
  }

  const getComplianceAuditGroupId = (clientId) => {
    return auditGroupId.find(x => x.clientId === clientId) || defaultAuditGroupId;
  }

  return (
    <ClientDashboardContext.Provider
      value={{
        fiscalYear,
        setFiscalYear,
        letterData,
        setLetterData,
        setMessageSendData,
        messageSendData,
        procedureEditData,
        setProcedureEditData,
        ledgerDetailData,
        setLedgerDetailData,
        setAuditGroupId: addComplianceAuditGroupId,
        getComplianceAuditGroupId
      }}
    >
      {props.children}
    </ClientDashboardContext.Provider>
  );
};

const defaultFiscalYearObject = {
  fiscalYearId: 0,
  fiscalYearValue: 0,
  procedureHeaderId: 0,
}

const letterDataDefault = {
  planningHdr: 0,
  initialValue: "",
  planningletterId: 0,
}

const ledgerDeatilsDefault = {
  clientId: "",
  clientName: "",
  natureOfBusiness: "",
  typeOfEntity: "",
  engagements: "",
  accountCode: "",
  accountName: "",
  fiscalYearID: "",
  fiscalYear: "",
  startDate: "",
  endDate: "",
  primaryGroupID: "",
  transactionType: "",
  stockregisterHdr: "",
  procedureCheckListID: "",
  ledgerID: "",
}


const procedureEditDataDefault = {
  clientId: "",
  clientName: "",
  natureOfBusiness: "",
  engagements: "",
  typeOfEntity:"",
  accountCode: "",
  fiscalYearID: "",
  accountName: "",
  amountData: "",
  primaryGroupID: "",
  procedureHdrID: "",
  procedureExistData: "",
  hasLedger: "",
  transactionType: "",
  stockregisterHdr: "",
  primaryGroup: "",
  scheduledStartDate: "",
  scheduledEndDate: "",
  ledgerID: "",
  scheduleGroupID: "",
  scheduleGroup:"",
  isLoading: false
}

const messageSendDataDefault = {
  fromID: "",
  fromUser: "",
  parentMessageID: 0,
  isFromCompany: "",
  recipientID: "",
  toUser: "",
  companyId: "",
}

const defaultAuditGroupId = {
  clientId: '',
  auditGroupId: 0,
  auditGroup: ''
}
