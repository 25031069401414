import React from 'react'
import create from "zustand";
import Dashboard from '../pages/Home/Dashboard';


export const useStore = create((set) => ({
  userData: null,
  SetUserData: (uData) =>
    set(() => ({
      userData: uData,
    })),

    lastMessage:null,
    SetLastMessage: (text) =>
    set(() => ({
      lastMessage: text,
    })),


    tabData:[{title: 'Home', content: <Dashboard />, key: 'Home', closable: false}],
    SetTabData: (NewTab) =>
    set((state) => ({
      tabData:[...state.tabData,NewTab] ,
    })),
    RemoveTabData: (NewTab) =>
    set(() => ({
      tabData:NewTab ,
    })),

    activeKey:"Home",
    setActiveKey: (key) =>
    set(() => ({
      activeKey: key,
    })),

    collapsed:true,
    setToggle: (key) =>
    set(() => ({
      collapsed: key,
    })),

    z_role_ID:0,
    setZRoleID: (id) =>
    set(() => ({
      z_role_ID: id,
    })),


    z_EngClintID:0,
    setz_EngClintID: (id) =>
    set(() => ({
      z_EngClintID: id,
    })),

    z_Client_Data:[],
    setZClientData: (rowData) =>
    set((state) => ({
      z_Client_Data: [rowData],
    })),

    z_User_Id:0,
    setZUserID: (id) =>
    set((state) => ({
      z_User_Id: id,
    })),


    spinDisply:'none',
    setspinDisply: (lbl) =>
    set((state) => ({
      spinDisply: lbl,
    })),


    menuListArr:[],
    setmenuListArr: (list) =>
    set((state) => ({
      menuListArr: list,
    })),

    dateFormat:"DD/MM/YYYY",
    setDateFormat: (frmt) =>
    set(() => ({
      dateFormat: frmt,
    })),


    decimalFormat:2,
    setDecimalFormat: (frmt) =>
    set(() => ({
      decimalFormat: frmt,
    })),


    TokenExpd:false,
    setTokenExpd: (stats) =>
    set(() => ({
      TokenExpd: stats,
    })),

    z_CompanyName:'',
    setZCompanyName:(companyName) =>
    set((state) => ({
      z_CompanyName: companyName,
    })),
    z_CompanyName:0,
    setZCompanyId:(companyId) =>
    set((state) => ({
      z_CompanyId: companyId,
    })),
    z_RiskEvalID:0,
    setz_RiskEvalID: (id) =>
    set(() => ({
      z_RiskEvalID: id,
    })),

    z_RiskCatID:0,
    setz_RiskCatID: (id) =>
    set(() => ({
      z_RiskCatID: id,
    })),
    

    z_ProjectID:0,
    setz_ProjectID: (id) =>
    set(() => ({
      z_ProjectID: id,
    })),

    z_RskAnlEvlID:0,
    setz_RskAnlEvlID: (id) =>
    set(() => ({
      z_RskAnlEvlID: id,
    })),
    
    z_ControlOwnerID:0,
    setz_ControlOwnerID: (id) =>
    set(() => ({
      z_ControlOwnerID: id,
    })),

    z_ScheduleID:0,
    setz_ScheduleID: (id) =>
    set(() => ({
      z_ScheduleID: id,
    })),

    z_FAScheduleID:0,
    setz_FAScheduleID: (id) =>
    set(() => ({
      z_FAScheduleID: id,
    })),

    z_PlanningID:0,
    setz_PlanningID: (id) =>
    set(() => ({
      z_PlanningID: id,
    })),

    z_ProcedureID:0,
    setz_ProcedureID: (id) =>
    set(() => ({
      z_ProcedureID: id,
    })),
    
    z_ComplianceAuditID:0,
    setz_ComplianceAuditID: (id) =>
    set(() => ({
      z_ComplianceAuditID: id,
    })),


    
    isLoggedIn:false,
    setIsLoggedIn: (Status) =>
    set(() => ({
      isLoggedIn: Status,
    })),

    clearAllStates: () =>
    set(() => ({
      tabData:[{title: 'Home', content: <Dashboard/>, key: 'Home', closable: false}],
      activeKey:'Home',
      userData:null,
      menuListArr:[],
      isLoggedIn:false,
    })),

    z_EngContID:0,
    setz_EngContID: (id) =>
    set(() => ({
      z_EngContID: id,
    })),

    z_IfrsID:0,
    setz_IfrsID: (id) =>
    set(() => ({
      z_IfrsID: id,
    })),


}));
