import { IconNames } from "@nws/nws-ui-library";
import { MenuCode } from "./PageNameForPermission";

export const accessLevelLabels = {
  accessAdd: "accessAdd",
  accessEdit: "accessEdit",
  accessView: "accessView",
  accessPrint: "accessPrint",
  accessApproval: "accessApproval",
  accessTask: "accessTask",
};

export const menuData = [
  {
    name: "menuItem.financialAudit",
    iconName: IconNames.NWAnalytics,
    uniqueKey: "menu-settings-main",
    menuId: 1,
    linkUrl: null,
    isSideBarMenu: true,
    children: [
      {
        name: "menuItem.contacts",
        iconName: IconNames.ForwardArrowBig,
        uniqueKey: "menu-settings-child-1-1",
        menuId: 5,
        linkUrl: "FinancialAuditing/Contacts/ContactList/ContactList",
        pageName: MenuCode.contacts,
        isSideBarMenu: true,
      },
      {
        name: "menuItem.lead",
        iconName: IconNames.ForwardArrowBig,
        uniqueKey: "menu-settings-child-1-2",
        menuId: 6,
        linkUrl: "FinancialAuditing/Lead/Lead",
        pageName: MenuCode.lead,
        isSideBarMenu: true,
      },
      {
        name: "menuItem.clients",
        iconName: IconNames.ForwardArrowBig,
        uniqueKey: "menu-settings-child-1-3",
        menuId: 7,
        linkUrl: "FinancialAuditing/Clients/ClientList",
        pageName: MenuCode.client,
        isSideBarMenu: true,
      },
      {
        name: "menuItem.engagement",
        iconName: IconNames.ForwardArrowBig,
        uniqueKey: "menu-settings-child-1-4",
        menuId: "",
        linkUrl: "FinancialAuditing/Engagement/EngagementList",
        pageName: MenuCode.engagement,
        isSideBarMenu: false,
      },
      {
        name: "menuItem.finalCompletion",
        iconName: IconNames.ForwardArrowBig,
        uniqueKey: "menu-settings-child-1-5",
        menuId: "",
        linkUrl: null,
        isSideBarMenu: false,
        children: [
          {
            name: "menuItem.ifrsChecklist",
            iconName: IconNames.ForwardArrowSmall,
            uniqueKey: "menu-settings-child-1-5-1",
            menuId: "",
            linkUrl: "FinancialAuditing/FinalCompletion/IFRSChecklist/IFRSList",
            pageName: MenuCode.ifrsChecklist,
            isSideBarMenu: false,
          },
          {
            name: "menuItem.partnerChecklist",
            iconName: IconNames.ForwardArrowSmall,
            uniqueKey: "menu-settings-child-1-5-2",
            menuId: "",
            linkUrl:
              "FinancialAuditing/FinalCompletion/PartnersChecklist/PartnersGridList",
            pageName: MenuCode.partnerChecklist,
            isSideBarMenu: false,
          },
          {
            name: "menuItem.finalReviewChecklist",
            iconName: IconNames.ForwardArrowSmall,
            uniqueKey: "menu-settings-child-1-5-3",
            menuId: "",
            linkUrl:
              "FinancialAuditing/FinalCompletion/FinalReviewChecklists/FinalReviewList",
            pageName: MenuCode.finalReviewChecklist,
            isSideBarMenu: false,
          },
          {
            name: "menuItem.auditOption",
            iconName: IconNames.ForwardArrowSmall,
            uniqueKey: "menu-settings-child-1-5-4",
            menuId: "",
            linkUrl:
              "FinancialAuditing/FinalCompletion/AuditOption/AuditOptionList",
            pageName: MenuCode.finalReviewChecklist,
            isSideBarMenu: false,
          },
          {
            name: "menuItem.letterOfRepresentation",
            iconName: IconNames.ForwardArrowSmall,
            uniqueKey: "menu-settings-child-1-5-5",
            menuId: "",
            linkUrl:
              "FinancialAuditing/FinalCompletion/LetterOfRepresentationCheckList/RepresentationList",
            pageName: MenuCode.representationLetter,
            isSideBarMenu: false,
          },
          {
            name: "menuItem.specificLetter",
            iconName: IconNames.ForwardArrowSmall,
            uniqueKey: "menu-settings-child-1-5-6",
            menuId: "",
            linkUrl:
              "FinancialAuditing/FinalCompletion/FinalCompletionSpecificLetters/SpecificLetterList",
            pageName: MenuCode.specificRepresentationLetter,
            isSideBarMenu: false,
          },
        ],
      },
      {
        name: "menuItem.invoice",
        iconName: IconNames.ForwardArrowBig,
        uniqueKey: "menu-settings-child-1-6",
        linkUrl: "FinancialAuditing/Invoice/InvoiceList/InvoiceList",
        menuId: 8,
        pageName: MenuCode.invoice,
        isSideBarMenu: true,
      },
      {
        name: "menuItem.statement",
        iconName: IconNames.ForwardArrowBig,
        uniqueKey: "menu-settings-child-1-7",
        menuId: "",
        linkUrl: null,
        isSideBarMenu: false,
        children: [
          {
            name: "menuItem.profitAndLoss",
            iconName: IconNames.ForwardArrowBig,
            uniqueKey: "menu-settings-child-1-7-1",
            menuId: "",
            linkUrl: null,
            pageName: MenuCode.profitAndLoss,
            isSideBarMenu: false,
          },
          {
            name: "menuItem.balanceSheet",
            iconName: IconNames.ForwardArrowBig,
            uniqueKey: "menu-settings-child-1-7-2",
            menuId: "",
            linkUrl: null,
            pageName: MenuCode.balanceSheet,
            isSideBarMenu: false,
          },
        ],
      },
      {
        name: "menuItem.engagementContinuesList",
        uniqueKey: "menu-settings-child-1-8",
        menuId: "",
        linkUrl:
          "FinancialAuditing/EngagementContinues/EngagementContinuesList",
        pageName: MenuCode.engagementContinous,
        isSideBarMenu: false,
      },
      {
        name: "menuItem.planningList",
        uniqueKey: "menu-settings-child-1-9",
        menuId: "",
        linkUrl: "FinancialAuditing/Planning/PlanningList",
        pageName: MenuCode.planning,
        isSideBarMenu: false,
      },
      {
        name: "menuItem.procedureEdit",
        uniqueKey: "menu-settings-child-1-10",
        menuId: "",
        linkUrl: "FinancialAuditing/Procedure/Procedure",
        pageName: MenuCode.procedure,
        isSideBarMenu: false,
      },
      {
        name: "menuItem.ledgerDetails",
        uniqueKey: "menu-settings-child-1-11",
        menuId: "",
        linkUrl: "FinancialAuditing/Procedure/LedgerDetails",
        pageName: MenuCode.substantiveProcedure,
        isSideBarMenu: false,
      },
    ],
  },
  {
    name: "menuItem.analytics",
    iconName: IconNames.FinancialAnalysis,
    uniqueKey: "menu-settings-2",
    menuId: "",
    linkUrl: null,
    isSideBarMenu: false,
  },
  {
    name: "menuItem.riskAudit",
    iconName: IconNames.NWRiskAudit,
    uniqueKey: "menu-settings-3",
    menuId: "",
    linkUrl: null,
    isSideBarMenu: false,
    children: [
      {
        name: "menuItem.projects",
        iconName: IconNames.ForwardArrowBig,
        uniqueKey: "menu-settings-child-3-1",
        menuId: "",
        linkUrl: null,
        isSideBarMenu: false,
      },
      {
        name: "menuItem.riskCategory",
        iconName: IconNames.ForwardArrowBig,
        uniqueKey: "menu-settings-child-3-2",
        menuId: "",
        linkUrl: null,
        isSideBarMenu: false,
      },
      {
        name: "menuItem.controlOwners",
        iconName: IconNames.ForwardArrowBig,
        uniqueKey: "menu-settings-child-3-3",
        menuId: "",
        linkUrl: null,
        isSideBarMenu: false,
      },
      {
        name: "menuItem.riskAnalysisEvaluation",
        iconName: IconNames.ForwardArrowBig,
        uniqueKey: "menu-settings-child-3-4",
        menuId: "",
        linkUrl: null,
        isSideBarMenu: false,
      },
      {
        name: "menuItem.setting",
        iconName: IconNames.ForwardArrowBig,
        uniqueKey: "menu-settings-child-3-5",
        menuId: "",
        linkUrl: null,
        isSideBarMenu: false,
        children: [
          {
            name: "menuItem.riskEvaluationCriteria",
            iconName: IconNames.ForwardArrowSmall,
            uniqueKey: "menu-settings-child-3-5-1",
            menuId: "",
            linkUrl: null,
            isSideBarMenu: false,
          },
          {
            name: "menuItem.schedule",
            iconName: IconNames.ForwardArrowSmall,
            uniqueKey: "menu-settings-child-3-5-2",
            menuId: "",
            linkUrl: null,
            isSideBarMenu: false,
          },
        ],
      },
    ],
  },
  {
    name: "menuItem.taskManagement",
    iconName: IconNames.SsRole,
    uniqueKey: "menu-task-management-6",
    linkUrl: null,
    menuId: 2,
    isSideBarMenu: true,
    children: [
      {
        name: "menuItem.taskDashboard",
        iconName: IconNames.ForwardArrowSmall,
        uniqueKey: "menu-task-management-6-1",
        linkUrl: "TaskManagement/TaskDashboard/TaskDashboardPage",
        menuId: 9,
        pageName: MenuCode.taskManagement,
        isSideBarMenu: true,
      },
      {
        name: "menuItem.taskBoard",
        iconName: IconNames.ForwardArrowBig,
        uniqueKey: "menu-task-management-6-2",
        menuId: 10,
        linkUrl: "TaskManagement/TaskBoard/TaskBoard",
        pageName: MenuCode.tasbBoard,
        isSideBarMenu: true,
      },
      {
        name: "menuItem.taskList",
        iconName: IconNames.ForwardArrowSmall,
        uniqueKey: "menu-task-management-6-3",
        linkUrl: "TaskManagement/Task/TaskList/TaskListPage",
        menuId: 11,
        pageName: MenuCode.taskList,
        isSideBarMenu: true,
      },
      {
        name: "menuItem.projectList",
        iconName: IconNames.ForwardArrowSmall,
        uniqueKey: "menu-task-management-6-4",
        linkUrl: "TaskManagement/Project/ProjectList/ProjectList",
        menuId: 12,
        pageName: MenuCode.projectList,
        isSideBarMenu: true,
      },
      {
        name: "menuItem.timeSheet",
        iconName: IconNames.ForwardArrowSmall,
        uniqueKey: "menu-task-management-6-7",
        linkUrl: "TaskManagement/TimeSheetMenu/TimeSheetPage",
        menuId: 13,
        pageName: MenuCode.timeSheet,
        isSideBarMenu: true,
      },
      {
        name: "menuItem.settingsdetails",
        iconName: IconNames.ForwardArrowBig,
        uniqueKey: "menu-task-management-6-5",
        menuId: 14,
        pageName: MenuCode.settings,
        isSideBarMenu: true,
        children: [
          {
            name: "menuItem.teams",
            iconName: IconNames.ForwardArrowSmall,
            uniqueKey: "menu-task-management-6-5-1",
            linkUrl: "TaskManagement/Teams/TeamList/TeamList",
            menuId: 15,
            pageName: MenuCode.teams,
            isSideBarMenu: true,
          },
          {
            name: "menuItem.clientSettings",
            iconName: IconNames.ForwardArrowSmall,
            uniqueKey: "menu-task-management-6-5-2",
            menuId: 16,
            linkUrl: "TaskManagement/ClientSettings/ClientSettings",
            pageName: MenuCode.clientSettings,
            isSideBarMenu: true,
          },
          {
            name: "menuItem.employeeSettings",
            iconName: IconNames.ForwardArrowSmall,
            uniqueKey: "menu-task-management-6-5-3",
            menuId: 17,
            linkUrl: "TaskManagement/EmployeeSettings/EmployeeSettings",
            pageName: MenuCode.employeeSettings,
            isSideBarMenu: true,
          },
          {
            name: "menuItem.clientCredentialMgr",
            iconName: IconNames.ForwardArrowSmall,
            uniqueKey: "menu-task-management-6-5-4",
            linkUrl:
              "TaskManagement/ClientCredentialManager/ClientCredentialManager",
            menuId: 18,
            pageName: MenuCode.clientCredentialMgr,
            isSideBarMenu: true,
          },
          {
            name: "menuItem.gradeMapping",
            iconName: IconNames.ForwardArrowSmall,
            uniqueKey: "menu-task-management-6-4-3",
            menuId: 19,
            linkUrl: "TaskManagement/GradeMapping/GradeMapping",
            pageName: MenuCode.gradeMapping,
            isSideBarMenu: true,
          },
          {
            name: "menuItem.taskSchedule",
            iconName: IconNames.ForwardArrowSmall,
            uniqueKey: "menu-task-management-6-5-6",
            menuId: 20,
            linkUrl: "TaskManagement/TaskSchedule/TaskSchedule",
            pageName: MenuCode.taskSchedule,
            isSideBarMenu: true,
          },
          {
            name: "menuItem.generalSettings",
            iconName: IconNames.ForwardArrowSmall,
            uniqueKey: "menu-task-management-6-5-7",
            menuId: 21,
            linkUrl: "TaskManagement/TaskGeneralSettings/TaskGeneralSettings",
            pageName: MenuCode.taskGeneralSettings,
            isSideBarMenu: true,
          },
        ],
      },
      {
        name: "menuItem.taskReports",
        iconName: IconNames.ForwardArrowBig,
        uniqueKey: "menu-task-management-report-6-6",
        menuId: 22,
        pageName: MenuCode.taskReports,
        isSideBarMenu: true,
        children: [
          {
            name: "menuItem.costAnalysisReport",
            iconName: IconNames.ForwardArrowBig,
            uniqueKey: "menu-task-management-6-6-1",
            menuId: 23,
            linkUrl: "TaskManagement/CostAnalysisReport/CostAnalysisReport",
            pageName: MenuCode.costAnalysisReport,
            isSideBarMenu: true,
          },
        ],
      },
    ],
  },
  {
    name: "menuItem.documents",
    iconName: IconNames.IconDocumentManagement,
    uniqueKey: "menu-document-management-7",
    linkUrl: null,
    menuId: 3,
    isSideBarMenu: true,
    className: "document-menu-icon",
    children: [
      {
        name: "documentManagement.documentManagement",
        iconName: IconNames.ForwardArrowBig,
        uniqueKey: "menu-settings-child-7-1",
        linkUrl: "DocumentManagement/DocumentSearch",
        menuId: 36,
        pageName: MenuCode.documentManagement,
        isSideBarMenu: true,
      },
    ],
  },
  {
    name: "menuItem.generalSettings",
    iconName: IconNames.NWSetting,
    uniqueKey: "menu-settings-4",
    menuId: 4,
    linkUrl: null,
    isSideBarMenu: true,
    children: [
      {
        name: "menuItem.company",
        iconName: IconNames.ForwardArrowBig,
        uniqueKey: "menu-settings-child-4-1",
        menuId: 24,
        linkUrl: "Users/Company/Company",
        pageName: MenuCode.company,
        isSideBarMenu: true,
      },
      {
        name: "menuItem.schedule",
        iconName: IconNames.ForwardArrowSmall,
        uniqueKey: "menu-settings-child-4-2",
        menuId: "",
        linkUrl: "FinancialAuditing/Schedule/ScheduleList",
        pageName: MenuCode.schedule,
        isSideBarMenu: false,
      },
      {
        name: "menuItem.statement",
        iconName: IconNames.ForwardArrowBig,
        uniqueKey: "menu-settings-child-4-3",
        menuId: "",
        linkUrl: null,
        isSideBarMenu: false,
        children: [
          {
            name: "menuItem.profitLossSetting",
            iconName: IconNames.ForwardArrowBig,
            uniqueKey: "menu-settings-child-4-3-1",
            menuId: "",
            linkUrl: "Statements/ProfitAndLossSettings/ProfitLossSettings",
            pageName: MenuCode.profitAndLossSetting,
            isSideBarMenu: false,
          },
          {
            name: "menuItem.balanceSheet",
            iconName: IconNames.ForwardArrowBig,
            uniqueKey: "menu-settings-child-4-3-2",
            menuId: "",
            linkUrl: "Statements/BalanceSheetSettings/BalanceSheetSettings",
            pageName: MenuCode.balanceSheetSetting,
            isSideBarMenu: false,
          },
          {
            name: "menuItem.fixedAssetIncomeTax",
            iconName: IconNames.ForwardArrowBig,
            uniqueKey: "menu-settings-child-4-3-3",
            menuId: "",
            linkUrl: "Statements/FixedAssetsITSettings/FixedAssetsITSettings",
            pageName: MenuCode.fixedAssetITSettings,
            isSideBarMenu: false,
          },
          {
            name: "menuItem.fixedAssetCompanyAct",
            iconName: IconNames.ForwardArrowBig,
            uniqueKey: "menu-settings-child-4-3-4",
            menuId: "",
            linkUrl: "Statements/FIxedAssetCASettings/FixedAseetsCASettings",
            pageName: MenuCode.fixedAssetCASettings,
            isSideBarMenu: false,
          },
          {
            name: "menuItem.profitAndLossAndBalanceSheet",
            iconName: IconNames.ForwardArrowBig,
            uniqueKey: "menu-settings-child-4-3-5",
            menuId: "",
            linkUrl: "Statements/ReportSetting/ReportAdditionalSettingsField",
            pageName: MenuCode.profitAndLossAndBalanceSheet,
            isSideBarMenu: false,
          },
        ],
      },
      {
        name: "menuItem.userManagement",
        iconName: IconNames.ForwardArrowBig,
        uniqueKey: "menu-settings-child-4-3",
        menuId: 25,
        linkUrl: null,
        isSideBarMenu: true,
        children: [
          {
            name: "menuItem.role",
            iconName: IconNames.ForwardArrowBig,
            uniqueKey: "menu-settings-child-4-3-1",
            menuId: 26,
            linkUrl: "FinancialAuditing/UserManagement/Role/RoleList",
            isSideBarMenu: true,
            pageName: MenuCode.role,
          },
          {
            name: "menuItem.users",
            iconName: IconNames.ForwardArrowBig,
            uniqueKey: "menu-settings-child-4-3-1",
            menuId: 27,
            linkUrl: "FinancialAuditing/UserManagement/Users/UserList",
            isSideBarMenu: true,
            pageName: MenuCode.user,
          },
          {
            name: "menuItem.accessClient",
            iconName: IconNames.ForwardArrowBig,
            uniqueKey: "menu-settings-child-4-3-1",
            menuId: 28,
            linkUrl: "GeneralSettings/AccessClient/AssignClient",
            isSideBarMenu: true,
            pageName: MenuCode.assignClient,
          },
        ],
      },
      {
        name: "menuItem.accountGrouping",
        iconName: IconNames.ForwardArrowBig,
        uniqueKey: "menu-settings-child-4-4",
        menuId: "",
        linkUrl: "GeneralSettings/AccountGrouping/AccountGroupingSettings",
        pageName: MenuCode.accountGrouping,
        isSideBarMenu: false,
      },
      {
        name: "menuItem.notes",
        iconName: IconNames.ForwardArrowBig,
        uniqueKey: "menu-settings-child-4-5",
        menuId: "",
        linkUrl: "GeneralSettings/Notes/Notes",
        pageName: MenuCode.notes,
        isSideBarMenu: false,
      },
      {
        name: "menuItem.emailBody",
        iconName: IconNames.ForwardArrowBig,
        uniqueKey: "menu-settings-child-4-6",
        menuId: 29,
        linkUrl: "GeneralSettings/EmailBody/EmailBody",
        pageName: MenuCode.emailBody,
        isSideBarMenu: true,
      },
      {
        name: "menuItem.engagementLetter",
        iconName: IconNames.ForwardArrowBig,
        uniqueKey: "menu-settings-child-4-7",
        menuId: 30,
        linkUrl: "GeneralSettings/Engagement/EngagementLetter",
        pageName: MenuCode.engagementLetter,
        isSideBarMenu: true,
      },
      {
        name: "menuItem.notesReport",
        iconName: IconNames.ForwardArrowBig,
        uniqueKey: "menu-settings-child-4-8",
        menuId: "",
        linkUrl: "GeneralSettings/NotesReport/NotePlBsSettings",
        pageName: MenuCode.notesReport,
        isSideBarMenu: false,
      },
      {
        name: "menuItem.auditReportSettings",
        iconName: IconNames.ForwardArrowBig,
        uniqueKey: "menu-settings-child-4-9",
        menuId: 31,
        linkUrl:
          "GeneralSettings/AuditReport/AuditReportSettings/AuditReportCommonSettings",
        pageName: MenuCode.auditReportSettings,
        isSideBarMenu: true,
      },
      {
        name: "menuItem.directorsReportSettings",
        iconName: IconNames.ForwardArrowBig,
        uniqueKey: "menu-settings-child-4-10",
        menuId: 32,
        linkUrl:
          "GeneralSettings/DirectorsReport/DirectorsReportSettings/DirectorCommonSettings",
        pageName: MenuCode.directorsReportSettings,
        isSideBarMenu: true,
      },
      {
        name: "menuItem.caroReportSettings",
        iconName: IconNames.ForwardArrowBig,
        uniqueKey: "menu-settings-child-4-11",
        menuId: 33,
        linkUrl:
          "GeneralSettings/CaroReport/CaroReportSettings/CaroReportCommonSettings",
        pageName: MenuCode.caroReportSettings,
        isSideBarMenu: true,
      },
      {
        name: "menuItem.cashFlowReportSettings",
        iconName: IconNames.ForwardArrowBig,
        uniqueKey: "menu-settings-child-4-12",
        menuId: 34,
        linkUrl:
          "GeneralSettings/CashFlowReport/CashFlowReportSettings/CashFlowReportCommonSettings",
        pageName: MenuCode.cashFlowReport,
        isSideBarMenu: true,
      },
      {
        name: "menuItem.materialityRange",
        iconName: IconNames.ForwardArrowBig,
        uniqueKey: "menu-settings-child-4-13",
        menuId: 35,
        linkUrl: "GeneralSettings/MaterialityRange/MaterialityRange",
        pageName: MenuCode.materialityRange,
        isSideBarMenu: true,
      },
    ],
  },
  {
    name: "menuItem.help",
    iconName: IconNames.NWHelp,
    uniqueKey: "menu-settings-5",
    linkUrl: null,
    isSideBarMenu: true,
  },
];
