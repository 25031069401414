import React from "react";
import { useTranslate } from "../../hooks/translation/useTranslationHook";

export const Footer = () => {
  const { t } = useTranslate();
  return (
    <div className="nw-login--footer">
      {t("loginFooter.copyRightText")}
    </div>
  );
};
