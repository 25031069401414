import React from "react";
import { DashboardCardContainer } from "./DashboardCardContainer";
import { LoadingSkeleton } from "./LoadingSkeleton ";
import { MenuIcon } from "@nws/nws-ui-library";
import { ActiveClientItem } from "./ActiveClientItem";
import { idleStatus } from "./Constants";

export const DashboardCardWithChild = ({
  item,
  handleOnClick,
  clientActiveList,
  clientListLoading,
  hasChild,
  clientListStatus,
}) => {
  const { iconName, displayLabel, children, shouldShow } = item;

  return (
    <DashboardCardContainer
      onClick={handleOnClick}
      shouldShow={shouldShow}
      minHeight="260px"
      hasChild={hasChild}
    >
      <div className="dashboard-card-container">
        {clientListStatus == idleStatus || clientListLoading ? (
          <LoadingSkeleton />
        ) : (
          <>
            <MenuIcon iconName={iconName} width="3.5em" height="3.5em" />
            <label>{displayLabel}</label>
          </>
        )}
      </div>
      <div>
        {children.map((child, index) =>
          clientListStatus == idleStatus || clientListLoading ? (
            <LoadingSkeleton key={`loader-${index}`} width="250px" />
          ) : (
            clientActiveList
              ?.filter((element) => element.item === child.type)
              .map((element, idx) => (
                <ActiveClientItem
                  key={`active-clients-${index}-${idx}`}
                  element={element}
                  child={child}
                />
              ))
          )
        )}
      </div>
    </DashboardCardContainer>
  );
};
