import React, { useState, useRef } from "react";
import { MenuIcon, IconNames } from "@nws/nws-ui-library";
import { useOutsideAlerter } from "../useOutsideAlerter";
import { menuData } from "../../Menu/MenuData";
import { addNewtabwindow } from "../../../Helpers/helper";
import { useStore } from "../../../store";
import { useTranslate } from "../../../hooks/translation/useTranslationHook";

export const SearchBar = (props) => {
  const { t } = useTranslate();
  const wrapperRef = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const [setTabData, tabData] = useStore((state) => [
    state.SetTabData,
    state.tabData,
  ]);
  const [setActiveKey, activeKey] = useStore((state) => [
    state.setActiveKey,
    state.activeKey,
  ]);
  const [setToggle, collapsed] = useStore((state) => [
    state.setToggle,
    state.collapsed,
  ]);

  useOutsideAlerter(wrapperRef, () => {
    setOpen(false);
  });

const menu = menuData
  .map((value) => ({ ...value, children: value.children ? [...value.children] : [] }))
  .filter((value) => value.isSideBarMenu);

const getAllFilteredMenuData = (item) => {
  if (item.children) {
    item.children = item.children
      .map((child) => ({ ...child, children: child.children ? [...child.children] : [] }))
      .filter((child) => child.isSideBarMenu);

    item.children.forEach((element) => {
      if (element) {
        getAllFilteredMenuData(element);
      }
    });
  }
};

menu.forEach((data) => getAllFilteredMenuData(data));

  const [search, setSearch] = useState("");
  const [foundTerm, setFoundTerm] = useState(menu);
  const filter = (e) => {
    const keyword = e.target.value;

    let results = [];

    const hasFound = (item) => {
      let found = t(item.name).toLowerCase().startsWith(keyword.toLowerCase());
      if (item.children) {
        item.children = item.children.filter((child) => child.isSideBarMenu);
        if (found) {
          results.push(item);
        }
        item.children.forEach((element) => {
          if (element) {
            hasFound(element);
          }
        });
      }

      return found;
    };

    if (keyword !== "") {
      results = [];
      menu.forEach((item) => hasFound(item));
      setFoundTerm(results);
    } else {
      setFoundTerm(menu);
    }

    setSearch(keyword);
  };

  const MenuItem = ({ item }) => {
    return (
      <ul>
        <li key={item.menuId} className="menuItems">
          <span onClick={() => openNewClient(item)}>{t(item.name)}</span>
          <ul>
            {item.children &&
              item.children.map((item, index) => (
                <MenuItem item={item} key={`new-client-${index}`} />
              ))}
          </ul>
        </li>
      </ul>
    );
  };

  const tabParams = [tabData, setTabData, setActiveKey, setToggle, false];
  const openNewClient = (foundTerm) => {
    foundTerm.linkUrl != null &&
      addNewtabwindow(t(foundTerm.name), foundTerm.linkUrl, ...tabParams, 0);
  };

  return (
    <div className="nw-header--search-container" ref={wrapperRef}>
      {/* <div className="nws-header-report-bug">
        <a
          href="https://forms.gle/CqpayZoCKwr4HUn69"
          target="_blank"
          title={t("searchBar.clickToRaiseBugOrFeature")}
        >
          {t("searchBar.raiseBugLabel")}
        </a>
      </div> */}
      <label className="nw-header--search-label">
        <span className="sr-only">{t("searchBar.searchText")}</span>

        <input
          className="nw-form-control nw-form-control--search nw-box-shadow"
          type="search"
          autoCorrect="off"
          name=""
          placeholder={t("searchBar.searchInputPlaceholder")}
          onChange={filter}
          value={search}
        />

        <button
          className="btn nw-btn nw-btn-search"
          onClick={() => setOpen(true)}
        >
          <MenuIcon width="0.9em" height="0.9em" iconName={IconNames.Search} />
          <em className="sr-only" onClick={(e) => e.preventDefault()}>
            {t("searchBar.searchText")}
          </em>
        </button>
        <div
          className={`nw-header-user-section   ${
            isOpen === true ? "active" : ""
          }`}
        >
          <div className="nw-header-user-section__items searchbar-popover">
            {foundTerm && foundTerm.length > 0 ? (
              foundTerm.map((page, index) => (
                <MenuItem item={page} key={`found-term-${index}`} />
              ))
            ) : (
              <p className="no-result">
                {t("searchBar.searchErrorNoResultText")}
              </p>
            )}
          </div>
        </div>
      </label>
    </div>
  );
};
