export const setUserLoginData = (data) => {
    sessionStorage.setItem("userData", JSON.stringify(data));
}
export const getUserLoginData = () => JSON.parse(sessionStorage.getItem("userData"));
export const setUserDateFormat = (dateFormat) => {
    localStorage.setItem('userDateFormat', dateFormat)
}
export const getUserDateFormat = () => localStorage.getItem("userDateFormat");
export const getUserName = () => getUserLoginData() ? getUserLoginData().userName : "";
export const getUserId = () => getUserLoginData() ? getUserLoginData().id : "";
export const getUserEmail = () => getUserLoginData() ? getUserLoginData().email : "";
export const getUserCompanyName = () => getUserLoginData() ? getUserLoginData().companyName : "";
export const getUserCompanyId = () => getUserLoginData() ? getUserLoginData().companyID : "";
export const getUserRefreshToken = () => getUserLoginData() ? getUserLoginData().refreshToken : "";
export const getUserDisplayDateFormat = () => getUserDateFormat() ? getUserDateFormat() : "";
export const getUserDateFormatForDatepicker = () => getUserDateFormat() ? getUserDateFormat().toLowerCase().split('m').join('M') : '';
export const getCountry = () => getUserLoginData() ? getUserLoginData().country : "";
export const getCountryId = () => getUserLoginData() ? getUserLoginData().countryID : "";