import React from 'react'
import CompanyName from './CompanyName'
import { LogoContainer } from './LogoContainer'
import { NotificationInfo } from './NotificationInfo'
import { SearchBar } from './SearchBar'
import { UserInfo } from './UserInfo'
export const HeaderBar = (props) => {

  return <header className="nw-app-main-header">
    <LogoContainer />
    <div className="nw-header-company-name">
      <CompanyName />
      <SearchBar />
    </div>
    <div className="nw-header-account-container">
      <UserInfo />
      <NotificationInfo />
    </div>
  </header>
}