import React, { useContext } from "react";
import { NWSGrid } from "@nws/nws-ui-library";
import { useTranslate } from "../../../hooks/translation/useTranslationHook";
import { taskInvoiceGridColumns } from "./taskInvoiceSummary.data";
import { addNewtabwindow } from "../../../Helpers/helper";
import { useTabParam } from "../../../hooks/common/useTabParam";
import {
  GlobalStateContext,
  GlobalStateKeys,
} from "../../../Context/GlobalStateContext";

export const TaskInvoiceSummary = (props) => {
  const { taskInvoiceData, showLoading } = props;
  const { t } = useTranslate();
  const { tabParams } = useTabParam();
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const invoiceDetails = globalState?.invoiceDetails || {};
  const defaultTax = 18;

  const handleInvoiceData = (values, printPage) => {
    setGlobalState(GlobalStateKeys.InvoiceDetails, {
      ...invoiceDetails,
      ...values,
      isPrintPage: printPage,
    });
  };

  const rowAction = (rData) => {
    handleInvoiceData(
      {
        clientListID: { id: rData.clientId, value: rData.client },
        invoiceData: null,
        invoiceDate: new Date(),
        taxValue: defaultTax,
        invoiceNumber: "",
        invoiceAddGridValues: {
          description: rData.description,
          hsn: "",
          amount: "",
        },
        invoiceId: 0,
        isPrintPage: false,
        taskId: rData.taskId,
        paymentMode: "0",
        invoiceRemark: "",
        taskInvoiceId: rData.id,
        paymentDate: null,
      },
      false
    );

    addNewtabwindow(
      t("invoice.invoiceAddOrEdit"),
      "FinancialAuditing/Invoice/Invoice/Invoice",
      ...tabParams,
      { refetch: () => {} }
    );
  };

  return (
    <div className="nw-dashboard-task-container">
      <div className="pb-1">{t("invoice.invoicePending")}</div>
      <NWSGrid
        newTable={true}
        columns={taskInvoiceGridColumns(t)}
        dataSource={taskInvoiceData || []}
        onRow={rowAction}
        hasPageSizeDropdown={false}
        showLoading={showLoading}
      />
    </div>
  );
};
