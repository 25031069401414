import React from "react";
import { useTranslate } from "../../hooks/translation/useTranslationHook";

export default function Picture() {
  const { t } = useTranslate();
  return (
    <div className="nw-col-7 nw-login-billboard-col">
      <div className="nw-login--billboard-wrap">
        <div className="nw-login--billboard-content">
          <div className="nw-login--billboard-content">
            <p className="nw-login-billboard--sub-title">
              {t("loginPicture.loginPictureQuote")}
            </p>
          </div>
        </div>
        <picture className="nw-login--billboard-graphic">
          <img
            className="nw-office-graphic"
            src="Images/login-billboard-graphic.svg"
            alt="Welcome back to Networth"
          />
        </picture>
      </div>
    </div>
  );
}
