import React, { useCallback, useEffect } from "react";
import Events from "../../../Helpers/pubsub/events";
import { pubSub } from "../../../Helpers/pubsub/pubsub";
import { useHistory } from "react-router-dom";
import { LogoutSession } from "../../../Helpers/helper";
import { useStore } from "../../../store";
import { useTranslate } from "../../../hooks/translation/useTranslationHook";

export const LogoutAllSession = () => {
  const { t } = useTranslate();
  const [clearAllStates] = useStore((state) => [state.clearAllStates]);
  const router = useHistory();
  const logoutSession = useCallback(() => {
    LogoutSession(clearAllStates, router);
  }, []);

  useEffect(() => {
    pubSub.subscribe(Events.APPLICATION_LOGOUT, logoutSession);
    return () => {
      pubSub.unSubscribe(Events.APPLICATION_LOGOUT, logoutSession);
    };
  });
  return <></>;
};
