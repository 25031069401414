import React, { createContext, useState } from "react";
export const GlobalStateContext = createContext();
/**
 * This is the Global state object and method to set the global state
 */
export const GlobalStateContextProvider = (props) => {
  const [globalState, setState] = useState({});

  const setGlobalState = (key, payload) => {
    const _globalState = { ...globalState };
    _globalState[key] = payload;
    setState(_globalState);
  };

  const setGlobalStateMany = (keyArray, payloadArray) => {
    const _globalState = { ...globalState };

    for (let i = 0; i < keyArray.length; i++) {
      _globalState[keyArray[i]] = payloadArray[i];
    }

    setState(_globalState);
  };

  return (
    <GlobalStateContext.Provider
      value={{
        globalState,
        setGlobalState,
        setGlobalStateMany,
      }}
    >
      {props.children}
    </GlobalStateContext.Provider>
  );
};
export const GlobalStateKeys = {
  ClientFiscalYears: "clientFiscalYears",
  EngagementListDetails: "engagementListDetails",
  EngagementDetails: "engagementDetails",
  RoleDetails: "roleDetails",
  UserDetails: "userDetails",
  InvoiceListDetails: "invoiceListDetails",
  InvoiceDetails: "invoiceDetails",
  ProfitAndLossSettings: "profitAndLossSettings",
  BalanceSheetSettings: "balanceSheetSettings",
  CompanyDetails: "companyDetails",
  ReloginPopupStatus: "reloginPopupStatus",
  EngagementLetterDetails: "engagementLetterDetails",
  ContactListDetails: "contactListDetails",
  LeadListDetails: "leadListDetails",
  ProjectListDetails: "projectListDetails",
  EmployeeSettingsDetails: "employeeSettingsDetails",
  DocumentListDetails: "documentListDetails",
  EmailBody: "emailBody",
  ClientListDetails: "clientListDetails",
  TaskListDetails: "taskListDetails",
  ClientSettingsDetails: "clientSettingsDetails",
  TeamListDetails: "teamListDetails",
  CostAnalysisReport: "costAnalysisReport",
  ClientCredentialManagerDetails: "clientCredentialManagerDetails",
  GradeMappingDetails: "gradeMappingDetails",
  TaskInvoiceDetails: "taskInvoiceDetails",
  PriorityTeamSettings: "priorityTeamSettings",
  ClientAccessDetails: "clientAccessDetails",
  AuditReportSettings: "auditReportSettings",
  DirectorsReportSettings: "directorReportSettings",
  CaroReportSettings: "caroReportSettings",
  TaskBoard: "taskBoard",
  CashFlowReportSettings: "cashFlowReportSettings",
  FeatureFlagDetails: "featureFlagDetails",
  TimeSheetDetails: "timeSheetDetails",
  MaterialityRange: "materialityRange",
  BalanceSheetReport:"balanceSheetReport",
  ChecklistSettings:"checklistSettings",
  TimesheetReport: "timesheetReport"
};
