import React, { useContext, useEffect, useState } from "react";
import { homeDashBoardCardTypes } from "./Constants";
import {
  getCurrentYearPlanning,
  getPlanningCompleted,
  getProcedureApprovePending,
  getProcedureCompleted,
  getProcedureInProgress,
  getProcedureReviewPending,
} from "../../../../Services/DashBoard.Services";
import { NWSGrid, NWSPopup } from "@nws/nws-ui-library";
import { clientDashBoardColumns } from "./DashboardCard.data";
import { ClientDataContext } from "../../../../Context/ClientContextContext";
import { ClientStateContext } from "../../../../Context/ClientStateContext";
import { addNewtabwindow } from "../../../../Helpers/helper";
import { useTabParam } from "../../../../hooks/common/useTabParam";

const DashboardCardPopup = ({
  popUpOpen,
  setPopUpOpen,
  itemDetails,
  setItemDetails,
  currentFiscalYearId,
  t,
  startDate,
  endDate,
}) => {
  const { tabParams } = useTabParam();
  const { setReportSelectedClient, setIsClientChanged } =
    useContext(ClientDataContext);
  const { setClientState } = useContext(ClientStateContext);
  const { type, label, hasScreenPermission, screenData } = itemDetails;
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, [currentFiscalYearId, type]);

  const fetchData = async () => {
    let result;
    let loading;
    switch (type) {
      case homeDashBoardCardTypes.PlanningCompleted:
        result = await getPlanningCompleted(currentFiscalYearId);
        loading = false;
        break;
      case homeDashBoardCardTypes.CurrentYearPlanning:
        result = await getCurrentYearPlanning(currentFiscalYearId);
        loading = false;
        break;
      case homeDashBoardCardTypes.ProcedureCompleted:
        result = await getProcedureCompleted(currentFiscalYearId);
        loading = false;
        break;
      case homeDashBoardCardTypes.ProcedureInProgress:
        result = await getProcedureInProgress(currentFiscalYearId);
        loading = false;
        break;
      case homeDashBoardCardTypes.ProcedureReviewPending:
        result = await getProcedureReviewPending(currentFiscalYearId);
        loading = false;
        break;
      case homeDashBoardCardTypes.ProcedureApprovePending:
        result = await getProcedureApprovePending(currentFiscalYearId);
        loading = false;
        break;
      default:
        return;
    }

    setData(result.result.data);
    setIsLoading(loading);
  };

  const onClose = () => {
    setData([]);
    setItemDetails({
      type: "",
      label: "",
    });
    setPopUpOpen(false);
  };

  const handleOnRow = (rData) => {
    const { clientID, clientName, fiscalYearID, fiscalYear, comments } = rData;

    const updateClientState = (clientStateKey, tabTitle, menuId) => {
      const clientStateData = {
        fiscalYear: {
          id: fiscalYearID,
          value: fiscalYear,
          startDate: startDate,
          endDate: endDate,
        },
        clientDetails: {
          clientId: clientID,
          clientName: clientName,
        },
        screenTitle: tabTitle,
        isLoading: true,
        menuId: menuId,
      };
      setReportSelectedClient(clientID);
      setIsClientChanged(true);
      setClientState(clientID, clientStateKey, clientStateData);
    };

    const openSpecificScreenTab = () => {
      const screenItems = screenData.find((item) =>
        comments.includes(item.uniqueKey)
      );
      if (!screenItems) return;

      updateClientState(
        screenItems.clientStateKey,
        screenItems.tabTitle,
        screenItems.menuId
      );
      addNewtabwindow(screenItems.tabTitle, screenItems.tabPath, ...tabParams);
    };

    openSpecificScreenTab();
  };

  return (
    <NWSPopup
      onClose={onClose}
      className=""
      open={popUpOpen}
      title={label}
      width="md"
      body={
        <div className="">
          <NWSGrid
            newTable={true}
            columns={clientDashBoardColumns(t)}
            dataSource={data || []}
            hasPageSizeDropdown={false}
            onRow={hasScreenPermission ? handleOnRow : undefined}
            showLoading={isLoading}
          />
        </div>
      }
    />
  );
};

export default DashboardCardPopup;
