import Events from "../../Helpers/pubsub/events";
import { pubSub } from "../../Helpers/pubsub/pubsub";
import { getUserLoginData, setUserLoginData } from "../../Helpers/user.helper";
import { excludeRefreshTokenAPI } from "../Constants/Http.constants";
import { loggerhelper } from "../LoggerService/LoggerHelper";
import { loginHttpHelper } from "./HttpHelper";
import refreshCallModel from "./RefreshCallModel";

const REFRESH_TOKEN_END_POINT = '/refreshToken';

export const setInterceptor = (axiosInstance) => {
  //Request interceptor
  axiosInstance.interceptors.request.use(
    function (config) {
      config.headers.common['Authorization'] =
        'Bearer ' + getUserLoginData()?.jwtToken;
      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    },
  );

  //Response interceptor
  axiosInstance.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      return response;
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      if (
        error.response.status === 401 &&
        error.config &&
        !error.config.__isRetryRequest &&
        !excludeRefreshTokenAPI.includes(error.request.responseURL) &&
        !refreshCallModel.isRefreshTokenInProgress
      ) {
        refreshCallModel.isRefreshTokenInProgress = true
        getRefreshToken(getUserLoginData()?.refreshToken, 1).then((res) => {
          if (res && res.data) {
            setUserLoginData(res.data);
          } else {
            pubSub.publish(Events.APPLICATION_LOGOUT);
          }
          refreshCallModel.isRefreshTokenInProgress = false;
        });
      }
      return Promise.reject(error);
    },
  );
};

const getRefreshToken = async (refreshToken, retryCount) => {
  try {
    let response = await loginHttpHelper.post(REFRESH_TOKEN_END_POINT, {
      refreshToken: `${refreshToken.toString()}`,
    });
    // if (
    //   response === undefined ||
    //   response.status === 500 ||
    //   response.status === 401 ||
    //   response.status === 403
    // ) {
    //   if (retryCount > 1) {
    //     pubSub.publish(Events.APPLICATION_LOGOUT);
    //   } else {
    //     getRefreshToken(refreshToken, retryCount + 1);
    //   }
    // }
    refreshCallModel.isRefreshTokenInProgress = false;
    return response;
  } catch (err) {
    refreshCallModel.isRefreshTokenInProgress = false;
    loggerhelper.error(err);
    // pubSub.publish(Events.APPLICATION_LOGOUT);
  }
};
