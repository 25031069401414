import React, { useState, useContext, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useTranslate } from "../../../hooks/translation/useTranslationHook";
import { getInvoiceReceiptSummary } from "../../../Services/Invoice.Services";
import { GlobalStateContext } from "../../../Context/GlobalStateContext";
import { chartMonth } from "./constants";
import { NWSSkeletonLoader } from "@nws/nws-ui-library";

export const InvoiceReceiptSummaryChart = () => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );
  const { t } = useTranslate();
  const { globalState } = useContext(GlobalStateContext);
  const clientAndFiscalYearsData = globalState?.clientFiscalYears || {};
  const { currentFiscalYear = { id: 0, value: "" } } = clientAndFiscalYearsData;
  const [monthList, setMonthList] = useState([]);
  const [invoiceSummaryData, setInvoiceSummaryData] = useState([]);
  const [receiptSummaryData, setReceiptSummaryData] = useState([]);
  const [showLoading, setShowLoading] = useState(true);

  const fetchInvoiceReceiptData = async (fiscalYearId) => {
    const { response } = await getInvoiceReceiptSummary(fiscalYearId);
    let data = response.data;
    data?.forEach((item) => {
      setMonthList((monthList) => [
        ...monthList,
        chartMonth.find((elem) => elem.id === item.month).name,
      ]);
      setInvoiceSummaryData((invoiceSummaryData) => [
        ...invoiceSummaryData,
        item.invoice,
      ]);
      setReceiptSummaryData((receiptSummaryData) => [
        ...receiptSummaryData,
        item.receipt,
      ]);
    });
    setShowLoading(false);
  };

  useEffect(() => {
    currentFiscalYear?.fiscalYearID &&
      fetchInvoiceReceiptData(currentFiscalYear.fiscalYearID);
  }, [currentFiscalYear]);

  const chartData = {
    labels: monthList,
    datasets: [
      {
        label: showLoading ? "" : t("invoice.invoice"),
        data: invoiceSummaryData,
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
      },
      {
        label: showLoading ? "" : t("invoice.receipt"),
        data: receiptSummaryData,
        fill: false,
        borderColor: "#742774",
      },
    ],
  };

  const chartOptions = {
    locale: "en-IN",
  };

  return (
    <div className="dashboard-invoice-card">
      {showLoading ? (
        <NWSSkeletonLoader type="inline" width={"250px"} />
      ) : (
        <div className="summary-title">{t("invoice.summaryChartLabel")}</div>
      )}
      <div className="dashboard-invoice-receipt-chart">
        <Line data={chartData} options={chartOptions} />
      </div>
    </div>
  );
};
