import { useEffect } from "react";
import { useQuery } from "react-query";
import { getNumberOfActiveClients } from "../../Services/DashBoard.Services";

const useActiveClients = (clientFiscalId) => {
  const shouldFetchData = clientFiscalId !== 0 && clientFiscalId !== undefined;
  const { isLoading, error, data, refetch, status } = useQuery(
    "clientActiveClients",
    () =>
      getNumberOfActiveClients(clientFiscalId).then(
        ({ result }) => result.data
      ),
    { refetchOnWindowFocus: false, enabled: false }
  );

  useEffect(() => {
    if (shouldFetchData) {
      refetch();
    }
  }, [clientFiscalId]);

  return {
    clientListLoading: isLoading,
    clientActiveList: data,
    clientListStatus: status,
  };
};

export default useActiveClients;
