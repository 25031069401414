import React, { useEffect, useState } from "react";
import { accessLevels, checkActionPermision } from "../../Helpers/helper";
import { InvoiceReceiptSummaryChart } from "../../components/Dashboard/InvoiceReceiptSummary/InvoiceReceiptSummaryChart";
import { TaskInvoiceSummary } from "../../components/Dashboard/TaskInvoiceSummary/TaskInvoiceSummary";
import { getTaskInvoiceSummary } from "../../Services/DashBoard.Services";
import { getUserId } from "../../Helpers/user.helper";
import { DashboardCard } from "../../components/Dashboard/DashboardCard/DashboardCard";
import { useMenuList } from "../../hooks/common/useMenuList";

export default function Dashboard() {
  const userId = getUserId();
  const { menuListArr } = useMenuList();
  const homeInvoiceSummaryMenuId = 42;
  const [showLoading, setShowLoading] = useState(false);
  const [taskInvoiceData, setTaskInvoiceData] = useState([]);
  const [taskInvoiceStyle, setTaskInvoiceStyle] = useState();
  const [invoiceSummaryStyle, setInvoiceSummaryStyle] = useState();

  useEffect(() => {
    const fetchTaskInvoiceSummary = async () => {
      setShowLoading(true);
      const { response } = await getTaskInvoiceSummary(userId);
      setTaskInvoiceData(response?.data || []);
      if (response?.data.length === 0) {
        setTaskInvoiceStyle("dashbard-item-fade-out");
      }
      setShowLoading(false);
    };

    fetchTaskInvoiceSummary();
  }, [userId]);

  useEffect(() => {
    const invoiceSummaryPermission = checkActionPermision(
      menuListArr,
      homeInvoiceSummaryMenuId,
      accessLevels.AccessView,
      true
    );

    setInvoiceSummaryStyle(
      invoiceSummaryPermission ? "" : "dashbard-item-fade-out"
    );
  }, [menuListArr]);

  return (
    <main className="height-100 nw-en-criteria">
      <div className="nw-en-criteria--header nw-dash-border-none">
        <DashboardCard />
      </div>
      <div
        className={`nw-en-criteria--header nw-dash-border-none ${taskInvoiceStyle}`}
      >
        <TaskInvoiceSummary
          showLoading={showLoading}
          taskInvoiceData={taskInvoiceData}
        />
      </div>
      <div
        className={`nw-en-criteria--header nw-dash-border-none ${invoiceSummaryStyle}`}
      >
        <InvoiceReceiptSummaryChart />
      </div>
    </main>
  );
}
