import httpHelper, { taskManagementHttpHelper } from "./HttpServiceUtils/HttpHelper";
import { loggerhelper } from "./LoggerService/LoggerHelper";
import { PageNames } from "./LoggerService/PageNames";

export const getNumberOfActiveClients = async (fiscID) => {
    try {
        loggerhelper.trace(PageNames.DASHBOARD, `Get Number of active clients,engagements,engagement continuous  `, `From { DashBoard}`, `${fiscID}`)
        const result = await httpHelper.get(`/noofactiveclients/${fiscID}`)
        loggerhelper.trace(PageNames.DASHBOARD, `Get Number of active clients,engagements,engagement continuous  `, `From { DashBoard}`, `${fiscID}`)
        return { result };
    } catch (err) {
        loggerhelper.error(err)
    }
};

export const getPlanningCompletedCount = async (fiscID) => {
    try {
        loggerhelper.trace(PageNames.DASHBOARD, `Get Number of completed planning `, `From { DashBoard}`, `${fiscID}`)
        const result = await httpHelper.get(`/noofplanning/${fiscID}/2`)
        loggerhelper.trace(PageNames.DASHBOARD, `Get Number of completed planning `, `From { DashBoard}`, `${fiscID}`)
        return { result };
    } catch (err) {
        loggerhelper.error(err)
    }
};

export const getProcedureInProgressCount = async (fiscID) => {
    try {
        loggerhelper.trace(PageNames.DASHBOARD, `Get Number of InProgress procedure `, `From { DashBoard}`, `${fiscID}`)
        const result = await httpHelper.get(`/noofprocedure/${fiscID}/1`)
        loggerhelper.trace(PageNames.DASHBOARD, `Get Number of InProgress procedure `, `From { DashBoard}`, `${fiscID}`)
        return { result };
    } catch (err) {
        loggerhelper.error(err)
    }
};

export const getProcedureCompletedCount = async (fiscID) => {
    try {
        loggerhelper.trace(PageNames.DASHBOARD, `Get Number of completed Procedure `, `From { DashBoard}`, `${fiscID}`)
        const result = await httpHelper.get(`/noofprocedure/${fiscID}/2`)
        loggerhelper.trace(PageNames.DASHBOARD, `Get Number of completed Procedure `, `From { DashBoard}`, `${fiscID}`)
        return { result };
    } catch (err) {
        loggerhelper.error(err)
    }
};

export const getCurrentYearPlanningCount = async (fiscID) => {
    try {
        loggerhelper.trace(PageNames.DASHBOARD, `Get Number of completed Procedure `, `From { DashBoard}`, `${fiscID}`)
        const result = await httpHelper.get(`/noofplanning/${fiscID}/1`)
        loggerhelper.trace(PageNames.DASHBOARD, `Get Number of completed Procedure `, `From { DashBoard}`, `${fiscID}`)
        return { result };
    } catch (err) {
        loggerhelper.error(err)
    }
};

export const getPlanningCompleted = async (fiscID) => {
    try {
        loggerhelper.trace(PageNames.DASHBOARD, `Get  completed planning  list`, `From { DashBoard}`, `${fiscID}`)
        const result = await httpHelper.get(`/planning/list/${fiscID}/2`)
        loggerhelper.trace(PageNames.DASHBOARD, `Get  completed planning  list`, `From { DashBoard}`, `${fiscID}`)
        return { result };
    } catch (err) {
        loggerhelper.error(err)
    }
};

export const getCurrentYearPlanning = async (fiscID) => {
    try {
        loggerhelper.trace(PageNames.DASHBOARD, `Get  Current  planning  list`, `From { DashBoard}`, `${fiscID}`)
        const result = await httpHelper.get(`planning/list/${fiscID}/1`)
        loggerhelper.trace(PageNames.DASHBOARD, `Get Current planning list`, `From { DashBoard}`, `${fiscID}`)
        return { result };
    } catch (err) {
        loggerhelper.error(err)
    }
};

export const getProcedureInProgress = async (fiscID) => {
    try {
        loggerhelper.trace(PageNames.DASHBOARD, `Get In progress procedure list`, `From { DashBoard}`, `${fiscID}`)
        const result = await httpHelper.get(`/procedure/list/${fiscID}/1`)
        loggerhelper.trace(PageNames.DASHBOARD, `Get  Current  planning  list`, `From { DashBoard}`, `${fiscID}`)
        return { result };
    } catch (err) {
        loggerhelper.error(err)
    }
};

export const getProcedureCompleted = async (fiscID) => {
    try {
        loggerhelper.trace(PageNames.DASHBOARD, `Get completed procedure list`, `From { DashBoard}`, `${fiscID}`)
        const result = await httpHelper.get(`/procedure/list/${fiscID}/2`)
        loggerhelper.trace(PageNames.DASHBOARD, `Get  Current  planning  list`, `From { DashBoard}`, `${fiscID}`)
        return { result };
    } catch (err) {
        loggerhelper.error(err)
    }
};


export const getActiveClients = async () => {
    try {
        loggerhelper.trace(PageNames.DASHBOARD, `Get Active clients list`, `From { DashBoard}`)
        const result = await httpHelper.get('/activeclients')
        loggerhelper.trace(PageNames.DASHBOARD, `Get Active clients list`, `From { DashBoard}`)
        return { result };
    } catch (err) {
        loggerhelper.error(err)
    }
};

export const getTaskInvoiceSummary = async (userId) => {
    try {
        const response = await taskManagementHttpHelper.get(`/taskInvoices/user/${userId}`)
        return { response };
    } catch (err) {
        loggerhelper.error(err)
    }
};

export const getProcedureReviewPendingCount = async (fiscID) => {
    try {
        const result = await httpHelper.get(`/procedure/reviewpending/${fiscID}`)
        return { result };
    } catch (err) {
        loggerhelper.error(err)
    }
};

export const getProcedureApprovePendingCount = async (fiscID) => {
    try {
        const result = await httpHelper.get(`/procedure/approvalpending/${fiscID}`)
        return { result };
    } catch (err) {
        loggerhelper.error(err)
    }
};

export const getProcedureReviewPending = async (fiscID) => {
    try {
        const result = await httpHelper.get(`/procedure/list/reviewpending/${fiscID}`)
        return { result };
    } catch (err) {
        loggerhelper.error(err)
    }
};

export const getProcedureApprovePending = async (fiscID) => {
    try {
        const result = await httpHelper.get(`/procedure/list/approvalpending/${fiscID}`)
        return { result };
    } catch (err) {
        loggerhelper.error(err)
    }
};