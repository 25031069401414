import { getUserCompanyId } from "../Helpers/user.helper";
import  { loginHttpHelper } from "./HttpServiceUtils/HttpHelper";
import { loggerhelper } from "./LoggerService/LoggerHelper";
import { PageNames } from './LoggerService/PageNames';

export const saveCompanytoUpdate = async (payload) => {
  try {
    loggerhelper.trace(PageNames.COMPANY, `Update Company details`, `from Users|Company `,``);
    const response = await loginHttpHelper.put("/Company", payload);
    loggerhelper.trace(PageNames.COMPANY, `Update Company details`, `from Users|Company `,``);
    return response ;
  } catch (err) {
    loggerhelper.error(err);
  }
};

export const getCompany = async () => {
  try{
    loggerhelper.trace(PageNames.COMPANY, `Get Company details`, `from Users|Company `);
    const response = await loginHttpHelper.get(`/Company/${getUserCompanyId()}`);
    const company = response.data;
    loggerhelper.trace(PageNames.COMPANY, `Get Company details`, `from Users|Company `);
    return { company };
  } catch (err) {
    loggerhelper.error(err);
  }
};

export const saveCaPartner = async (payload) => {
  try {
    loggerhelper.trace(PageNames.COMPANY, `Update CA Partner details`, `from Users|Company `,``);
    const response = await loginHttpHelper.put("/caPartners", payload);
    loggerhelper.trace(PageNames.COMPANY, `Update CA Partner details`, `from Users|Company `,``);
    return { response } ;
  } catch (err) {
    loggerhelper.error(err);
  }
};

export const getCaPartner = async (companyId) => {
  try {
    loggerhelper.trace(PageNames.COMPANY, `Get CA Partner details`, `from Users|Company `,``);
    const response = await loginHttpHelper.get(`/caPartners/company/${companyId}`);
    loggerhelper.trace(PageNames.COMPANY, `Get CA Partner details`, `from Users|Company `,``);
    return { response } ;
  } catch (err) {
    loggerhelper.error(err);
  }
};