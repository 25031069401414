import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { MESSAGE_COUNT_POLL_INTERVAL } from "../../components/Shared/Constants/Polling.constant";
import { getInboxUnreadCountFromAllClients } from "../../Services/Message.Services";

const useUserInfo = (companyId) => {
  const [count, setCount] = useState();
  const { isLoading, error, data, refetch } = useQuery(
    "fetchingMessageCount",
    () =>
      getInboxUnreadCountFromAllClients(companyId).then(
        ({ response }) => response.data || 0
      ),
    {
      refetchOnWindowFocus: false,
      refetchInterval: MESSAGE_COUNT_POLL_INTERVAL,
      refetchIntervalInBackground: true,
    }
  );
  useEffect(() => {
    refetch();
  }, [count]);

  return { isLoading, error, messageCount: data, setCount };
};

export default useUserInfo;
