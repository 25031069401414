import React, { createContext, useState } from "react";

export const ClientDataContext = createContext();

/**
 * Context to keep the client specific data .
 */
export const ClientContextProvider = (props) => {
  const [clientDetails, setClientDetails] = useState({
    clientId: 0,
    clientName: "",
    natureOfBusiness: "",
    engagements: "",
    address1: "",
    cinNo: "",
    email: "",
    mobileNo: ""
  });

  const [formValues, setformValues] = useState(defaultClientFormValues);
  const [clientRecordId, setClientRecordId] = useState('');
  const [categoryID, setCategoryID] = useState("0");
  const [engagmts, setEngagmts] = useState([]);
  const [typeOfEntity, setTypeOfEntity] = useState("0");
  const [natureOfBusiness, setNatureOfBusiness] = useState("0");
  const [typeOfClient, setTypeOfClient] = useState("0");
  const [status, setStatus] = useState("0");
  const [isActive, setIsActive] = useState(true);
  const [cinLabel, setCinLabel] = useState('');
  const [leadData,setLeadData] = useState({})
  const [reportSelectedClient , setReportSelectedClient] = useState(0)
  const [isClientChanged ,setIsClientChanged] = useState(false)

  return (
    <ClientDataContext.Provider
      value={{
        clientDetails,
        setClientDetails,
        formValues, 
        setformValues,
        clientRecordId, 
        setClientRecordId,
        categoryID, 
        setCategoryID,
        engagmts, 
        setEngagmts,
        typeOfEntity, 
        setTypeOfEntity,
        natureOfBusiness, 
        setNatureOfBusiness,
        typeOfClient, 
        setTypeOfClient,
        status, 
        setStatus,
        isActive, 
        setIsActive,
        cinLabel,
        setCinLabel,
        leadData,
        setLeadData,
        reportSelectedClient,
        setReportSelectedClient,
        isClientChanged,
        setIsClientChanged,
      }}
    >
      {props.children}
    </ClientDataContext.Provider>
  );
};


export const defaultClientFormValues={
  name: "",
  address1: "",
  address2: "",
  address3: "",
  contactPerson: "",
  contactPersonPAN: "",
  contactPersonAadhar: "",
  contactPersonPhone: "",
  contactPersonEmail: "",
  email: "",
  phoneLandLine: "",
  phoneMobile: "",
  pan: "",
  gstin: "",
  cin: "",
  password: "",
  tan: "",
  ieCcode: "",
  isActive: false,
}