import React, { useCallback, useEffect, useState } from 'react'
import Events from '../../Helpers/pubsub/events';
import { pubSub } from '../../Helpers/pubsub/pubsub';

export const LaodingSpinner = () => {
    const[isLoading, setLoading] = useState(false);
    
    const showLoading = useCallback(()=>{setLoading(true)}, [])
    const hideLoading = useCallback(()=>{setLoading(false)}, [])

    useEffect(()=>{
        pubSub.subscribe(Events.LOADING_INITIATED, showLoading)
        pubSub.subscribe(Events.LOADING_COMPLETED, hideLoading)
        return () => {
            pubSub.unSubscribe(Events.LOADING_INITIATED, showLoading)
            pubSub.unSubscribe(Events.LOADING_COMPLETED, hideLoading)
        }
    })

    return <div className="fp-container" style = {{ display: isLoading ? "block" : "none"}}>
        <div className="lds-ripple" ><div></div><div></div></div>
    </div>
}