import { ColumnTypes, IconNames } from "@nws/nws-ui-library";
export const messageListData = 
    [
        {
            title: 'Log',
            dataIndex: 'userLog',
            width: '5%',
            columnType: ColumnTypes.IconButton, iconName: IconNames.MetroStarFull, iconColor: 'red', 
        },
        {
            title: ' from User',
            dataIndex: 'fromUser',
            width: '35%'
        },
        {
            title: 'subject',
            dataIndex: 'subject',
            width: '45%',
        },
        {
            title: 'send Date',
            dataIndex: 'sendDate',
            width: '10%',
            cssClass: "checklist",
            isMainColumn: false,
            columnType:ColumnTypes.DateField,
        }
        
    ]

