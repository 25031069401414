import React from "react";

export const DashboardCardContainer = ({
  children,
  onClick,
  shouldShow,
  minHeight = "auto",
  hasChild,
}) => (
  <section
    className={`${!hasChild && "dashboard-card-container cursor-pointer"}`}
    style={shouldShow ? { minHeight } : { minHeight, display: "none" }}
    onClick={onClick}
  >
    {children}
  </section>
);
