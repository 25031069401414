class PubSub {

    /**
     * The proeprty to hold the event and its subscribers
     */
    Subscribers = [];

    constructor () {
        this.Subscribers = []
    }

    /**
     * Subscribe an event
     * @param eventName - Name of the event
     * @param callBack - Event handler
     */
    subscribe(eventName, callBack) {
        this.Subscribers.push({EVENT_NAME: eventName, callBack : callBack });
    }

    /**
     * Publish an event with a payload
     * @param eventName - Name of the event
     * @param payLoad - the object contains data to be published along with event
     */
    publish(eventName, payLoad) {
        this.Subscribers.filter(x => x.EVENT_NAME === eventName).forEach((element) => {
            element.callBack(payLoad);
        });
    }

    /**
     * Unsubscribe an event handler
     * @param eventName - Name of the event
     * @param callBack - Event handler
     */
    unSubscribe(eventName, callBack) {
        this.Subscribers = this.Subscribers.filter(x=> !(x.EVENT_NAME === eventName && x.callBack === callBack));
    }
}

var pubSub = new PubSub();
export {pubSub}