import { messageHttpHelper } from "./HttpServiceUtils/HttpHelper";
import { loggerhelper } from "./LoggerService/LoggerHelper";
import { PageNames } from './LoggerService/PageNames';

export const sendMessage = async (data) => {
    try {
        loggerhelper.trace(PageNames.CLIENTDASHBOARDMESSAGE, `Send Message  `, `from Client Dashboard|Messaging `, `${JSON.stringify(data)}`);
        const response = await messageHttpHelper.post('/message', data);       
        loggerhelper.trace(PageNames.CLIENTDASHBOARDMESSAGE, `Send Message  `, `from Client Dashboard|Messaging `, `${JSON.stringify(data)}`);
        return { response };
    } catch (err) {
        loggerhelper.error(err);  
    }
}

export const fetchMessageInbox = async (recipientID,fromID,companyId) => {
    try {
        loggerhelper.trace(PageNames.CLIENTDASHBOARDMESSAGE, `Fetching Message Inbox Data`, `from Client Dashboard|Messaging `, `${recipientID,fromID,companyId}`);
        const response = await messageHttpHelper.get(`/message/inbox/${fromID}/${recipientID}/${companyId}`);
        loggerhelper.trace(PageNames.CLIENTDASHBOARDMESSAGE, `Fetching Message Inbox Data`, `from Client Dashboard|Messaging `, `${recipientID,fromID,companyId}`);
        return { response };
    } catch (err) {
        loggerhelper.error(err)
    }
};

export const fetchMessageOutbox = async (fromID,recipientID,companyId) => {
    try {
        loggerhelper.trace(PageNames.CLIENTDASHBOARDMESSAGE, `Fetching Message Outbox Data`, `from Client Dashboard|Messaging `, `${fromID,recipientID,companyId}`);
        const response = await messageHttpHelper.get(`/message/outbox/${fromID}/${recipientID}/${companyId}`);
        const formattedData = response.data
        loggerhelper.trace(PageNames.CLIENTDASHBOARDMESSAGE, `Fetching Message Inbox Data`, `from Client Dashboard|Messaging `, `${fromID, recipientID,companyId}`);
        return { formattedData };
    } catch (err) {
        loggerhelper.error(err)
    }
};

export const displayMessage = async (messageId) => {
    try {
        loggerhelper.trace(PageNames.CLIENTDASHBOARDMESSAGE, `Display Message `, `from Client Dashboard|Messaging `, `${messageId}`);
        const response = await messageHttpHelper.get(`/message/${messageId}`);
        loggerhelper.trace(PageNames.CLIENTDASHBOARDMESSAGE, `Display Message `, `from Client Dashboard|Messaging `, `${messageId}`);
        return { response };
    } catch (err) {
        loggerhelper.error(err)
    }
};

export const viewedMessage = async (messageId) => {
    try {
        loggerhelper.trace(PageNames.CLIENTDASHBOARDMESSAGE, `Display Message `, `from Client Dashboard|Messaging `, `${messageId}`);
        const response = await messageHttpHelper.put(`/message/${messageId}`);
        loggerhelper.trace(PageNames.CLIENTDASHBOARDMESSAGE, `Display Message `, `from Client Dashboard|Messaging `, `${messageId}`);
        return { response };
    } catch (err) {
        loggerhelper.error(err)
    }
};



export const getInboxFromAllClients = async (companyId) => {
    try {
        loggerhelper.trace(PageNames.CLIENTDASHBOARDMESSAGE, `Fetching Message Inbox Data from All clients`, `from Client Dashboard|Messaging `, `${companyId}`);
        const response = await messageHttpHelper.get(`/message/unread/${companyId}`);
        loggerhelper.trace(PageNames.CLIENTDASHBOARDMESSAGE, `Fetching Message Inbox Data from All clients`, `from Client Dashboard|Messaging `, `${companyId}`);
        return { response };
    } catch (err) {
        loggerhelper.error(err)
    }
};


export const getInboxUnreadCountFromAllClients = async (companyId) => {
    try {
        loggerhelper.trace(PageNames.CLIENTDASHBOARDMESSAGE, `Fetching Message Inbox Unread count`, `from Client Dashboard|Messaging `, `${companyId}`);
        const response = await messageHttpHelper.get(`/message/totalunread/${companyId}`);
        loggerhelper.trace(PageNames.CLIENTDASHBOARDMESSAGE, `Fetching Message Inbox Unread count`, `from Client Dashboard|Messaging `, `${companyId}`);
        return { response };
    } catch (err) {
        loggerhelper.error(err)
    }
};