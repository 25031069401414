import React, { createContext, useState } from "react";
import { defaultSelect } from "../components/Shared/Constants/DefaultDropdownSelectedText";
import { defaultListObj } from "../Helpers/constants";

export const FinalCompletionContext = createContext();

/**
 * Context to keep the final completion specific data .
 */
export const FinalCompletionContextProvider = (props) => {
    ////partners checklist
    const [selectedPartnersListFiscalYearID, setSelectedPartnersListFiscalYearID] = useState(defaultListObj(false, false, false));
    const [selectedPartnersListClientListID, setSelectedPartnersListClientListID] = useState(defaultListObj(false, false, false))
    const [partnersFiscalYearID, setPartnersFiscalYearID] = useState(defaultSelect)
    const [partnersClientListID, setPartnersClientListID] = useState(defaultSelect)
    const [partnersID, setPartnersID] = useState(0)
    ////Ifrs checklist
    const [selectedIfrsListClientListId, setSelectedIfrsListClientListId] = useState(defaultListObj(false, false, false))
    const [selectedIfrsListFiscalYearID, setSelectedIfrsListFiscalYearID] = useState(defaultListObj(false, false, false));
    const [ifrsFiscalYearID, setIfrsFiscalYearID] = useState(defaultSelect)
    const [ifrsClientListID, setIfrsClientListID] = useState(defaultSelect)
    ////final review checklist
    const [selectedFinalReviewListlientListId, setSelectedFinalReviewListlientListId] = useState(defaultListObj(false, false, false))
    const [selectedFinalReviewListFiscalYearId, setSelectedFinalReviewListFiscalYearId] = useState(defaultListObj(false, false, false));
    const [reviewFiscalYearID, setReviewFiscalYearID] = useState(defaultSelect)
    const [reviewClientListID, setReviewClientListID] = useState(defaultSelect)
    const [finalReviewID, setfinalReviewID] = useState(0)
    ////specific letter
    const [selectedSpecificListClientListId, setSelectedSpecificListClientListId] = useState(defaultListObj(false, false, false))
    const [selectedSpecificListFiscalYearId, setSelectedSpecificListFiscalYearId] = useState(defaultListObj(false, false, false));
    const [specificFiscalYearID, setSpecificFiscalYearID] = useState(defaultSelect)
    const [specificClientListID, setSpecificClientListID] = useState(defaultSelect)
    const [specificLetterId, setSpecificLetterId] = useState(0)
    ////justification Checklist
    const [selectedJustificationListFiscalYearId, setSelectedJustificationListFiscalYearId] = useState(defaultListObj(false, false, false));
    const [selectedJustificationClientListId, setSelectedJustificationClientListId] = useState(defaultListObj(false, false, false))
    const [justificationFiscalYearID, setJustificationFiscalYearID] = useState(defaultSelect)
    const [justificationClientListID, setJustificationClientListID] = useState(defaultSelect)
    const [auditOptionID, setauditOptionID] = useState(0)
    ////representation letter
    const [selectedRepresentationListFiscalYearId, setSelectedRepresentationListFiscalYearId] = useState(defaultListObj(false, false, false));
    const [selectedRepresentionListClientListId, setSelectedRepresentionListClientListId] = useState(defaultListObj(false, false, false))
    const [representationLetterFiscalYearID, setRepresentationLetterFiscalYearID] = useState(defaultSelect)
    const [representationLetterClientListID, setRepresentationLetterClientListID] = useState(defaultSelect)
    const [representationId, setRepresentationId] = useState(0)

    return (
        <FinalCompletionContext.Provider
            value={{
                partnersFiscalYearID,
                setPartnersFiscalYearID,
                partnersClientListID,
                setPartnersClientListID,
                partnersID,
                setPartnersID,
                ifrsFiscalYearID,
                setIfrsFiscalYearID,
                ifrsClientListID,
                setIfrsClientListID,
                reviewFiscalYearID,
                setReviewFiscalYearID,
                reviewClientListID,
                setReviewClientListID,
                finalReviewID,
                setfinalReviewID,
                specificFiscalYearID,
                setSpecificFiscalYearID,
                specificClientListID,
                setSpecificClientListID,
                specificLetterId,
                setSpecificLetterId,
                justificationFiscalYearID,
                setJustificationFiscalYearID,
                justificationClientListID,
                setJustificationClientListID,
                auditOptionID,
                setauditOptionID,
                representationLetterFiscalYearID,
                setRepresentationLetterFiscalYearID,
                representationLetterClientListID,
                setRepresentationLetterClientListID,
                representationId,
                setRepresentationId,
                selectedIfrsListClientListId,
                setSelectedIfrsListClientListId,
                selectedIfrsListFiscalYearID,
                setSelectedIfrsListFiscalYearID,
                selectedIfrsListFiscalYearID,
                setSelectedIfrsListFiscalYearID,
                selectedPartnersListFiscalYearID,
                setSelectedPartnersListFiscalYearID,
                selectedPartnersListClientListID,
                setSelectedPartnersListClientListID,
                selectedFinalReviewListlientListId,
                setSelectedFinalReviewListlientListId,
                selectedFinalReviewListFiscalYearId,
                setSelectedFinalReviewListFiscalYearId,
                selectedJustificationClientListId,
                setSelectedJustificationClientListId,
                selectedJustificationListFiscalYearId,
                setSelectedJustificationListFiscalYearId,
                selectedRepresentionListClientListId,
                setSelectedRepresentionListClientListId,
                selectedRepresentationListFiscalYearId,
                setSelectedRepresentationListFiscalYearId,
                selectedSpecificListFiscalYearId,
                setSelectedSpecificListFiscalYearId,
                selectedSpecificListClientListId,
                setSelectedSpecificListClientListId
            }}
        >
            {props.children}
        </FinalCompletionContext.Provider>
    );
};
