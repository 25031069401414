
class Loggerhelper {
    constructor() {
    }

    /**
     * Logging an info
     * @param {string} page  - Name of the page , eg: 'LOGIN', 'HOME'
     * @param {string} message  - The message eg 'Login page loaded'
     * @param {string} origin - The complete pathe eg: 'Client | Clinet New | Save'
     * @param {Object} additionalDetails  - {}  - any dynamic additional details 
     */
    info(page, message, origin, additionalDetails) {

    }

    /**
     * Log a trace
     * @param {string} page  - Name of the page , eg: 'LOGIN', 'HOME'
     * @param {string} message  - The message eg 'Login page loaded'
     * @param {string} action  - The action of trace  , eg: 'Save button click'
     * @param {Object} additionalDetails  - {}  - any dynamic additional details 
     */
    trace(page, message, action, additionalDetails) {

    }

    /**
     * Log and eror
     * @param {string} page  - Name of the page , eg: 'LOGIN', 'HOME'
     * @param {string} errorCode  - The error code from ErroCodes
     * @param {string} error  - The error mesage 
     * @param {string} callStack  - Call stack if any
     * @param {*} innerException  - inner exception if any
     * @param {Object} additionalDetails  - {}  - any dynamic additional details 
     */
    error(page, errorCode, error, callStack, innerException, additionalDetails) {

    }
}

const ErrorCodes = {
    ComponentExceptions: 4001,
    APIFailures: 5001,
    JavascriptExceptions: 6001,
    OtherExceptions: 7001,
    NetworkFailures: 8001
}

const loggerhelper = new Loggerhelper();

export {loggerhelper, ErrorCodes}


// TODO: remove later 

    // const log = {
    //     page: string,
    //     time: datetime ,
    //     sessionId: string, 
    //     userId: guid,
    //     username: string,
    //     message: string,
    //     origin: string,
    //     additionalDetails : {}
    // }

    // const trace = {
    //     page: string,
    //     time: datetime ,
    //     sessionId: string, 
    //     userId: guid,
    //     username: string,
    //     message: string,
    //     origin: string,
    //     additionalDetails : {}
    // }

    // const error = {
    //     page: string,
    //     time: datetime ,
    //     sessionId: string, 
    //     userId: guid,
    //     username: string,
    //     errorCode: string,
    //     error: string,
    //     callStack: string,
    //     innerException: string ,
    //     origin: string,
    //     additionalDetails : {}
    // }