import React from 'react'

export default function LargeLogoContainer() {

    return (
        <header className="nw-login--header nws-login--header" >
            <img className="nw-login--header-logo nws-special--logo" src={"Images/networth-logo.svg"} 
            width="200" alt="Networth Logo"/>
        </header>
    )
}
