import React from "react";

export const ActiveClientItem = ({ element, child }) => (
  <div
    className={`active-clients ${
      child.hasBorder ? "active-clients-border" : ""
    }`}
  >
    <strong>{element.totalValue}</strong>
    <p>{child.displayLabel}</p>
  </div>
);
