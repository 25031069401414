import { IconNames, MenuIcon } from "@nws/nws-ui-library";
import React, { useRef, useState } from "react";
import { useOutsideAlerter } from "../useOutsideAlerter";
import ChangePassword from "./ChangePassword";
import UserProfileUpdate from "./UserProfilePopup";
import { useStore } from "../../../store";
import { useHistory } from "react-router-dom";
import { LogoutSession } from "../../../Helpers/helper";
import {
  getUserEmail,
  getUserName,
} from "../../../Helpers/user.helper";
import { LogoutAllSession } from "./LogoutAllSession";
import { useTranslate } from "../../../hooks/translation/useTranslationHook";
import { userChangePassword } from "../../../Services/ChangePassword.Services";
import { changePasswordTypes } from "../Constants/ChangePasswordConstants";

export const NotificationInfo = (props) => {
  const { t } = useTranslate();
  const [clearAllStates] = useStore((state) => [state.clearAllStates]);
  const router = useHistory();
  const [openModal, setOpenModal] = useState(false);

  const handleClickOpenModal = () => {
    setOpenModal(true);
  };

  const [userProfileopenModal, setUserProfileOpenModal] = React.useState(false);

  const handleuserProfileClickOpenModal = () => {
    setUserProfileOpenModal(true);
  };

  const wrapperRef = useRef(null);
  const [isOpen, setOpen] = useState(false);

  useOutsideAlerter(wrapperRef, () => {
    setOpen(false);
  });

  const onUserIconClickClick = (type) => {
    if (type === 1) {
      handleuserProfileClickOpenModal();
    } else {
      handleClickOpenModal();
    }
    setOpen(false);
  };

  const handleLogout = () => {
    LogoutSession(clearAllStates, router)
  }

  return (
    <>
      <em className="nw-devider">|</em>
      <div className="nw-header-notif-tray-wrap">
        <div className="nw-header-notif-spot" onClick={() => setOpen(true)}>
          <a
            className="nw-icon-link"
            href=""
            onClick={(e) => e.preventDefault()}
            ref={wrapperRef}
          >
            <div
              className={`nw-header-user-section ${isOpen === true ? " active" : ""
                }`}
            >
              <img
                className="sv-icon ico-notif-callout"
                src={"Images/Icon-avatar.svg"}
                width="200"
                alt="user-icon-logo"
                onClick={(e) => e.preventDefault}
              />
              <div className="nw-header-user-section__items">
                <ul>
                  <li>
                    <b>{getUserName()}</b>
                  </li>
                  <li>{getUserEmail()}</li>
                </ul>
                <hr className="nw-header-user-popover-hr" />
                <ul>
                  <li onClick={() => onUserIconClickClick(1)}>
                    {t("commonLabels.profile")}
                  </li>
                  <li onClick={() => onUserIconClickClick(2)}>
                    {t("commonLabels.changePassword")}
                  </li>
                </ul>
                <hr className="nw-header-user-popover-hr" />
                <ul>
                  <li onClick={handleLogout}>
                    <MenuIcon
                      iconName={IconNames.SignOut}
                      className="svg-menu-icon nw-header-svg-logout-icon"
                    />
                    {t("commonLabels.signout")}
                  </li>
                </ul>
              </div>
            </div>
          </a>
        </div>
      </div>
      <ChangePassword
        openModal={openModal}
        setOpenModal={setOpenModal}
        passwordChange={userChangePassword}
        changePasswordType={changePasswordTypes.changeUserPassword}
      />
      <UserProfileUpdate
        userProfileopenModal={userProfileopenModal}
        setUserProfileOpenModal={setUserProfileOpenModal}
      />
      <LogoutAllSession />
    </>
  );
};
