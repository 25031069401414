import {
  NWSPopup,
  NWSTextField,
} from "@nws/nws-ui-library";
import React, { useState } from "react";
import { FooterButton } from "../../Shared/FooterButton";
import { nwstoast, toastType } from "../../../Helpers/NWSToast";
import { useStore } from "../../../store";
import { useTranslate } from "../../../hooks/translation/useTranslationHook";
import { changePasswordTypes } from "../Constants/ChangePasswordConstants";

export default function ChangePassword({ openModal, setOpenModal, passwordChange, changePasswordType }) {
  const { t } = useTranslate();

  const handleClose = () => {
    setOpenModal(false);
  };

  const [changePassword, setChangePassword] = useState("");
  const [changeConfimPassword, setChangeConfimPassword] = useState("");
  const [SetUserData, userData] = useStore((state) => [
    state.SetUserData,
    state.userData,
  ]);
  const updatePassword = () => {
    if (changePassword.length <= 0) {
      nwstoast(
        t("commonToast.enterPassword"),
        toastType.Error
      );

      return false;
    } else if (changeConfimPassword.length <= 0) {
      nwstoast(
        t("commonToast.enterConfirmPassword"),
        toastType.Error
      );

      return false;
    } else if (changeConfimPassword != changePassword) {
      nwstoast(
        t("userDetails.userChangePasswordPopup.passwordMismatchToast"),
        toastType.Error
      );

      return false;
    }

    passwordChange({
      id: changePasswordType === changePasswordTypes.changeUserPassword ? userData.id : userData.companyID,
      password: changePassword,
    }).then(({ response }) => {
      if (response) {
        nwstoast(
          t("userDetails.userChangePasswordPopup.passwordSuccess"),
          toastType.Success
        );
        setChangePassword("");
        setChangeConfimPassword("");
        handleClose();
      } else {
        nwstoast(
          t("userDetails.userChangePasswordPopup.passwordError"),
          toastType.Error
        );
      }
    });
  };

  return (
    <NWSPopup
      onClose={handleClose}
      className=""
      open={openModal}
      header={
        <span> {t("commonLabels.changePassword")}</span>
      }
      title={t("commonLabels.changePassword")}
      width="sm"
      body={
        <div className="d-grid">
          <NWSTextField
            id="Password"
            label={t("commonLabels.newPassword")}
            type="password"
            InputLabelProps={{
              shrink: true,
            }}
            value={changePassword}
            onChange={(e) => {
              setChangePassword(e.target.value);
            }}
            style={{ width: "400px" }}
            isRequired={true}
          />
          <NWSTextField
            id="Confirm Password"
            label={t("commonLabels.confirmPassword")}
            type="password"
            InputLabelProps={{
              shrink: true,
            }}
            value={changeConfimPassword}
            onChange={(e) => {
              setChangeConfimPassword(e.target.value);
            }}
            style={{ width: "400px" }}
            isRequired={true}
          />
        </div>
      }
      footer={
        <div>
          <FooterButton onSave={updatePassword} type="Save" />
        </div>
      }
    />
  );
}
