import { NWSTooltip } from "@nws/nws-ui-library";
import React, { useState } from "react";
import { NotificationMessage } from "../../Message/NotificationMessage";
import { getUserCompanyId } from "../../../Helpers/user.helper";
import useUserInfo from "../../../hooks/HomeDashBoard/useUserInfo";

export const UserInfo = (props) => {
  const [open, setOpen] = useState(false);
  const companyId = getUserCompanyId();
  const { messageCount, setCount } = useUserInfo(companyId);

  let slicedCount = 0;
  if (messageCount && messageCount.toString().length > 3) {
    slicedCount = messageCount;
    slicedCount = String(messageCount)[0];
  }

  return (
    <>
      <em className="nw-devider">|</em>
      <div className="nw-header-avatar-wrap">
        <div className="nw-header-avatar-spot">
          <NWSTooltip title={`${messageCount}`}>
            <a
              className="nw-icon-link nw-notification-icon-link"
              onClick={() => setOpen(true)}
            >
              <img
                className="sv-icon ico-mag-glass nw-header-profile-icon"
                src={"Images/Notification-bell.svg"}
                width="200"
                alt="Networth Logo"
              />
              {messageCount >= 1 ? (
                <span className="nw-span-badge">
                  {slicedCount > 0 ? `${slicedCount}..` : messageCount}
                </span>
              ) : null}
            </a>
          </NWSTooltip>
        </div>
      </div>
      <NotificationMessage
        open={open}
        onClose={() => setOpen(false)}
        setMessageCount={setCount}
      />
    </>
  );
};
