import { getUserCompanyId } from "../../Helpers/user.helper";
import httpHelper, { taskManagementHttpHelper } from "../HttpServiceUtils/HttpHelper";
import { loggerhelper } from "../LoggerService/LoggerHelper";
import { PageNames } from "../LoggerService/PageNames";

export const saveTask = async (id, data) => {
    try {
        if (id == 0) {
            loggerhelper.info(PageNames.TASKMANAGEMENTPROJECT, `Creating a new project `, `from Task Management | Dashboard |Create Project | Add `, `${data}`);
            const response = await taskManagementHttpHelper.post('/Tasks', data);
            loggerhelper.info(PageNames.TASKMANAGEMENTPROJECT, `Creating a new project `, `from Task Management | Dashboard |Create Project | Add `, `${data}`);
            return response;
        } else {
            loggerhelper.info(PageNames.TASKMANAGEMENTPROJECT, `Updating a Project  details`, `from Task Management| Dashboard |Create Project | Edit  `, `${data, id}`);
            const response = await taskManagementHttpHelper.put('/Tasks', data);
            loggerhelper.info(PageNames.TASKMANAGEMENTPROJECT, `Updating a Project  details`, `from Task Management| Dashboard |Create Project | Edit  `, `${data, id}`);

            return response;
        }
    } catch (err) {
        loggerhelper.error(err)
    }

};

export const getTaskPriority = async () => {
    try {
        const response = await taskManagementHttpHelper.get(`/Filter/TaskPriority`);
        return response;
    } catch (err) {
        loggerhelper.error(err);
    }
};


export const getTaskStatus = async () => {
    try {
        const response = await taskManagementHttpHelper.get(`/Filter/TaskStatus`);
        return response;
    } catch (err) {
        loggerhelper.error(err);
    }
};


export const getTaskType = async () => {
    try {
        const response = await taskManagementHttpHelper.get(`/Filter/TaskType`);
        return response;
    } catch (err) {
        loggerhelper.error(err);
    }
};

export const getProjects = async () => {
    try {
        const response = await taskManagementHttpHelper.get(`/Projects?companyId=${getUserCompanyId()}`);
        return response;
    } catch (err) {
        loggerhelper.error(err);
    }
};

export const getTaskListData = async (taskListFilters) => {
    try {
        const response = await taskManagementHttpHelper.get(`Tasks/search${appendSerachQueryString(taskListFilters)}`);
        return { response };
    } catch (err) {
        loggerhelper.error(err);
    }
}
export const getAllTaskListData = async (companyId, assignedTo) => {
    try {
        const response = await taskManagementHttpHelper.get(`Tasks/search?companyId=${companyId}&assignedTo=${assignedTo}`);
        return { response };
    } catch (err) {
        loggerhelper.error(err);
    }
}

export const getScheduleTaskListData = async (companyId) => {
    try {
        const response = await taskManagementHttpHelper.get(`TaskSchedules?companyId=${companyId}`);
        return { response };
    }
    catch (err) {
        loggerhelper.error(err);
    }
}

export const createScheduledTask = async (data) => {
    try {
        const response = await taskManagementHttpHelper.post(`TaskSchedules`, data);
        return { response }
    }
    catch (err) {
        loggerhelper.error(err);
    }
}

export const deleteScheduledTask = async (taskId) => {
    try {
        const response = await taskManagementHttpHelper.delete(`TaskSchedules/${taskId}`);
        return { response }
    }
    catch (err) {
        loggerhelper.error(err);
    }
}

export const editScheduledTask = async (data) => {
    try {
        const response = await taskManagementHttpHelper.put(`TaskSchedules/`, data);
        return { response }
    }
    catch (err) {
        loggerhelper.error(err);
    }
}

export const getTask = async (taskId) => {
    const response = await taskManagementHttpHelper.get(`/Tasks/${taskId}`);
    return response;
};

export const getTaskAssignee = async (companyId, clientId) => {
    const response = await taskManagementHttpHelper.get(`/Tasks/assignee?companyId=${companyId}&clientId=${clientId}`);
    return response;
}

export const deleteTask = async (taskId) => {
    const response = await taskManagementHttpHelper.delete(`/Tasks?id=${taskId}`);
    return response;
};

export const getTaskBoardData = async (fromdate, todate) => {
    try {
        const response = await taskManagementHttpHelper.get(`Tasks/board?companyId=${getUserCompanyId()}&fromdate=${fromdate.toISOString()}&toDate=${todate.toISOString()}`);
        return response;
    } catch (err) {
        loggerhelper.error(err);
    }
}

export const getClientCredentialData = async (id) => {
    try {
        const response = await taskManagementHttpHelper.get(`ClientCredentialManager/${id}`);
        return response;
    } catch (err) {
        loggerhelper.error(err);
    }
}

export const getViewChecklistData = async (id) => {
    try {
        const response = await taskManagementHttpHelper.get(`ProjectChecklist/${id}`);
        return response;
    } catch (err) {
        loggerhelper.error(err);
    }
}


export const updateTask = async ({ id, status, priorityId, updatedBy, updatedDate }) => {
    try {
        const response = await taskManagementHttpHelper.patch('/Tasks', { id, status, priorityId, updatedBy, updatedDate });
        return response;
    } catch (err) {
        loggerhelper.error(err);
    }
}

const appendSerachQueryString = (taskListFilters) => {
    var queryString = `?companyId=${getUserCompanyId()}`;
    if (taskListFilters.projectId?.[0]?.id) {
        queryString += `&projectId=${taskListFilters.projectId?.[0]?.id}`
    }
    if (taskListFilters.typeId?.[0]?.id) {
        queryString += `&typeId=${taskListFilters.typeId?.[0]?.id}`
    }
    if (taskListFilters.parentTaskId) {
        queryString += `&parentTaskId=${taskListFilters.parentTaskId}`
    }
    if (taskListFilters.includeStatus?.[0]?.id) {
        queryString += `&includeStatus=${taskListFilters.includeStatus?.[0]?.id}`
    }
    if (taskListFilters.excludeStatus) {
        queryString += `&excludeStatus=${taskListFilters.excludeStatus?.[0]?.id}`
    }
    if (taskListFilters.clientId?.[0]?.id) {
        queryString += `&clientId=${taskListFilters.clientId?.[0]?.id}`
    }
    if (taskListFilters.assignedTo?.[0]?.id) {
        queryString += `&assignedTo=${taskListFilters.assignedTo?.[0]?.id}`
    }
    if (taskListFilters.assignedBy?.[0]?.id) {
        queryString += `&assignedBy=${taskListFilters.assignedBy?.[0]?.value}`
    }
    if (taskListFilters.invoiceStatus?.[0]?.id) {
        queryString += `&invoiceStatus=${taskListFilters.invoiceStatus?.[0]?.value}`
    }
    return queryString
}

export const saveTaskInvoice = async (id, data) => {
    if (id == 0) {
        loggerhelper.info(PageNames.INVOICE, `Save task invoice `, `from Task Management | save |Save Task Invoice | Add `, `${data}`);
        const response = await taskManagementHttpHelper.post('/TaskInvoices', data);
        loggerhelper.info(PageNames.INVOICE, `Save task invoice `, `from Task Management | Dasboard |Save Task Invoice | Add `, `${data}`);
        return response;
    }
    else {
        loggerhelper.info(PageNames.TASKMANAGEMENTPROJECT, `Updating a Project  details`, `from Task Management| Dashboard |Create Project | Edit  `, `${data, id}`);
        const response = await taskManagementHttpHelper.patch('/TaskInvoices/assignee', data);
        loggerhelper.info(PageNames.TASKMANAGEMENTPROJECT, `Updating a Task Invoice  details`, `from Task Management| Dashboard |Create Project | Edit  `, `${data, id}`);
        return response;
    }
};

export const updateTaskInvoice = async (data) => {
    loggerhelper.info(PageNames.INVOICE, `Updating a Project  details`, `from Task Management| Dashboard |Update Task Invoices| Edit  `, `${data}`);
    const response = await taskManagementHttpHelper.patch('/TaskInvoices', data);
    loggerhelper.info(PageNames.INVOICE, `Updating a Project  details`, `from Invoice| Dashboard |Update Task Invoices | Edit  `, `${data}`);
    return response;
}

export const getTaskInvoice = async (taskId) => {
    const response = await taskManagementHttpHelper.get(`/TaskInvoices/${taskId}`);
    return response;
};

export const getTaskInvoiceDetails = async (invoiceId) => {
    const response = await taskManagementHttpHelper.get(`/TaskInvoices/invoice/${invoiceId}`);
    return response;
};

export const saveTaskReview = async (payload) => {
  try {
    loggerhelper.trace(PageNames.TASKMANAGEMENTPROJECT,`Save Task  Data`,`from Task Management | Dashboard |Create Project | Add `);
    const response = await taskManagementHttpHelper.post("/tasks/review", payload);
    return response;
  } catch (err) {
    loggerhelper.error(err);
  }
};

export const saveTimeSheetData = async (payload) =>{
    try{
        loggerhelper.trace(PageNames.TASKMANAGEMENTPROJECT,`Save Timesheet  Data`,`from Timesheet | Dashboard |Create TimeSheet | Add `);
    const response = await taskManagementHttpHelper.post("/tasks/timesheet", payload);
    return response;
    }
    catch(err) {
        loggerhelper.error(err);
    }
}

export const deleteTimeSheet = async (timeSheetID) =>{
    try{
        loggerhelper.trace(PageNames.TASKMANAGEMENTPROJECT,`Delete Timesheet  Data`,`from Timesheet | Dashboard |Delete TimeSheet | Delete `);
    const response = await taskManagementHttpHelper.delete(`/tasks/timesheet?timesheetid=${timeSheetID}`);
    return response;
    }
    catch(err) {
        loggerhelper.error(err);
    }
}

//feature flag

export const getAllFeatureFlagStatus = async (companyId) => {
  try {
    const allFeatureFlags = await taskManagementHttpHelper.get(`/FeatureFlagConfig/?companyId=${companyId}`);
    return { allFeatureFlags };
  } catch (err) {
    loggerhelper.error(err);
  }
};

export const getTaskCheckLIst = async (taskId) => {
    try {
      const  response  = await taskManagementHttpHelper.get(`Tasks/${taskId}/checklist`);
      return response 
    } catch (err) {
      loggerhelper.error(err);
    }
};

export const updateTaskCheckList = async (data) => {
    try {
      loggerhelper.trace(PageNames.TASKMANAGEMENTPROJECT,`Update Task Checklist  Data`,`from TaskManagement| Task|ViewCheckList`);
      const response = await taskManagementHttpHelper.put( `Tasks/checklist`, data);
      return { response };
    } catch (err) {
      loggerhelper.error(err);
    }
  };
 
  //checklist attachment
 
  export const uploadChecklistAttachment = async (formData) => {
    try {
      loggerhelper.trace(PageNames.TASKMANAGEMENTPROJECT,`Post Files `,`from TaskManagement| Task|ViewCheckList `,`using ${formData}`,);
      const result = await httpHelper.post('/document/upload/single',formData,{},);
      loggerhelper.trace(
        PageNames.TASKMANAGEMENTPROJECT,`Post Files `,`from TaskManagement| Task|ViewCheckList `,`using ${formData}`,);
      return { result };
    } catch (err) {
      loggerhelper.error(err);
    }
  };
 
export const saveAttachment = async (id, data) => {
    try {
        loggerhelper.info(PageNames.TASKMANAGEMENTPROJECT, `Upload Attachment`, `from TaskManagement| Task|ViewCheckList `, `${data, id}`);
        if (id == 0) {
            const response = await taskManagementHttpHelper.post('/tasks/checklist/document', data);
            return { response };
        } else {
            const response = await taskManagementHttpHelper.put('/tasks/checklist/document', data);
            loggerhelper.trace(PageNames.TASKMANAGEMENTPROJECT, `Upload Attachment`, `from TaskManagement| Task|ViewCheckList `, `${data, id}`);
            return { response };
        }
    } catch (err) {
        loggerhelper.error(err)
    }
};
 